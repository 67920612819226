import React, { useState } from "react";

import { Table, TableHead, TableRow, TableCell, TableBody, Button } from "@material-ui/core";
import P from "../../../styled-components/planStyle"

import SubMember from "../../../components/PopUp/subMember";
import EditContextPopup from "./editContextPopup";


export default function CouponListTableMore({ item, expandedData, isEnd}) {
  const [perItem, setPerItem] = useState([]);
  const [perInfo, setPerInfo] = useState([]);
  const [btnStatus, setBtnStatus] = useState("");

  const [popupOpen, setPopupOpen] = useState(false);

  const headers1 = ["등록 코드", "담당자", "담당자 이메일", "담당자 연락처", "특이사항"];
  const headers2 = ["NO.", "이름", "이메일", "회사", "옵션"];

  // edit popup
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [paidSeq, setPaidSeq] = useState(0);
  const [paidNote, setPaidNote] = useState("");
  const [editPart, setEditPart] = useState("");
  const [prevData, setPrevData] = useState(null);

  const handleSubMemberBtn = () => {
    setPopupOpen(true);
  }

  const handleEditIcon = (item, clickPart, data) => {
    setPaidSeq(item.paid_seq);
    setPaidNote(item.note);
    setEditPart(clickPart);
    setPrevData(data);

    setEditPopupOpen(true);
  }

  if (!expandedData || expandedData.length === 0) {
    return (
      <></>
    );
  }

  return (
    <>
      <Table style={{ tableLayout: "fixed", position: "relative", width: "700%", cursor: "default" }}>
        {/* 상단 테이블 */}
        <TableHead style={{ backgroundColor: "#F0F0F0" }}>
          <TableRow>
            {headers1.map((header, index) => (
              <TableCell key={index} style={{ textAlign: "center" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ backgroundColor: "#F9F9F9" }}>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>{item.grant_code || "없음"}</TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <P.EditableDiv>
                <>{item.manager || "없음"}</>
                <P.FaRegEditIcon
                  onClick={() => handleEditIcon(item, "담당자", item.manager)}
                />
              </P.EditableDiv>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <P.EditableDiv>
                <p>{item.manager_email || "없음"}</p>
                <P.FaRegEditIcon
                  onClick={() => handleEditIcon(item, "담당자 이메일", item.manager_email)}
                />
              </P.EditableDiv>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <P.EditableDiv>
                <p>{item.manager_phone || "없음"}</p>
                <P.FaRegEditIcon
                  onClick={() => handleEditIcon(item, "담당자 연락처", item.manager_phone)}
                />
              </P.EditableDiv>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <P.EditableDiv>
                <p>{item.comment || "없음"}</p>
                <P.FaRegEditIcon
                  onClick={() => handleEditIcon(item, "특이사항", item.comment)}
                />
              </P.EditableDiv>
            </TableCell>
          </TableRow>
        </TableBody>

        {!isEnd &&
          <>
            <TableHead style={{ backgroundColor: "#F0F0F0" }}>
              <TableRow>
                {headers2.map((header, index) => (
                  <TableCell key={index} style={{ textAlign: "center" }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>

            </TableHead>
            {expandedData.map((info, index) => (
              <TableBody key={index} style={{ backgroundColor: "#F9F9F9" }}>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{info.name || "없음"}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{info.email || "없음"}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{info.org_name || "없음"}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {info.name ? (
                      <Button
                        style={{ backgroundColor: "#EA6A6A", color: "white", fontWeight: "medium", boxShadow: "none" }}
                        variant="contained"
                        onClick={() => {
                          setPerItem(item);
                          setPerInfo(info);
                          setBtnStatus("제거");
                          handleSubMemberBtn();
                        }}
                      >
                        제거
                      </Button>
                    ) : (
                      <Button
                        style={{ backgroundColor: "#3085D6", color: "white", fontWeight: "medium", boxShadow: "none" }}
                        variant="contained"
                        onClick={() => {
                          setPerItem(item);
                          setPerInfo(info);
                          setBtnStatus("추가");
                          handleSubMemberBtn();
                        }}
                      >
                        추가
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </>
        }
      </Table>
      
      {/*팝업*/}

      {/*멤버 삭제 추가*/}
      {popupOpen ? <SubMember closePopup={() => setPopupOpen(false)} perItem={perItem} perInfo={perInfo} btnStatus={btnStatus}/> : null}
      {/*내용 변경*/}
      {editPopupOpen ? <EditContextPopup closePopup={() => setEditPopupOpen(false)} paidSeq = {paidSeq} paidNote = {paidNote} editPart = {editPart} prevData = {prevData} /> : null}
    </>
  );
}
