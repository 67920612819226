// 결제 내역 관리 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Tab,
  Box,
} from "@material-ui/core";
import {TabList, TabPanel, TabContext} from '@material-ui/lab';
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import styled from 'styled-components';
import axios from 'axios';
import PaymentTable from "./components/PaymentTable";

function changePaymentData(array, index1, index2){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    item[index1] = value1.split('T')[0];
    if (value2 === null){
      item[index2] = '';
    } else { item[index2] = value2.split('T')[0]; }
  })
  // console.log(array);
  return array;
}


export default function Payment(){
  const [tableList, setTableList] = useState([]);
  const [deletedTableList, setDeletedTableList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row = ["결제 상태","UID/MID","주문명","고객 정보","결제 수단","결제 금액","상태 승인 시각"];
  const row2 = ["NO", "구매자", "구매 상품", "가격", "사용 회원 수", "상품 번호", "생성일", "종료일", "메모"];
  const searchTargets = ["grade_name", "name", "메모"];

  //tab
  const [value, setValue] = useState("2");
  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  //pagination api
  useEffect(() => {
    axios.get("/api/payment/completePayment")
      .then((response) => {
        setTableList(changePaymentData([...response.data.data], "paid_date", "end_date").reverse())
        console.log('결제 취소 찾기 :', response.data.data);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  //deleted api
  useEffect(() => {
    axios.get("/api/payment/deletedPayment")
      .then((response) => {
        setDeletedTableList(changePaymentData([...response.data.data], "paid_date", "end_date").reverse())
        console.log('결제 취소 찾기 :', response.data.data);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);


  return(
    <div>
      <PageTitle title="결제 내역" />
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .PrivateTabIndicator-root-62.PrivateTabIndicator-colorSecondary-64.MuiTabs-indicator': { backgroundColor:'#6BB9FF' } }} style={{paddingLeft: '24px'}}>
            <TabList onChange={tabChange} aria-lable="프로젝트 관리">
              {/*<Tab label="미결제" value="1" />*/}
              <Tab label="결제 완료" value="2" />
              <Tab label="결제 취소" value="3" />
              {/*<Tab label="결제 실패" value="4" />*/}
            </TabList>
          </Box>
          <Grid style={{paddingLeft: '24px', position: 'relative', top: '24px'}}>
            <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
              <SearchInput
                placeholder="Search"
                inputProps={{'aria-label': 'Search'}}
                onChange = {getValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </SearchPaper>
          </Grid>
          {/*미결제*/}
          {/*<TabPanel value="1" label="미결제">*/}
          {/*  <Grid item xs={12} >*/}
          {/*    <Widget>*/}
          {/*      /!*<Table row={row} filterKeyword={searchInput} totalData={}/>*!/*/}
          {/*    </Widget>*/}
          {/*  </Grid>*/}
          {/*</TabPanel>*/}
          {/*결제 완료*/}
          <TabPanel value="2" label="결제 완료">
            <Grid item xs={12} >
              <Widget>
                <PaymentTable row={row2} filterKeyword={searchInput} totalData={tableList} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
          {/*결제 취소*/}
          <TabPanel value="3" label="결제 취소">
            <Grid item xs={12} >
              <Widget>
                <PaymentTable row={row2} filterKeyword={searchInput} totalData={deletedTableList} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
          {/*결제 실패*/}
          {/*<TabPanel value="4" label="결제 실패">*/}
          {/*  <Grid item xs={12} >*/}
          {/*    <Widget>*/}
          {/*      /!*<Table row={row} filterKeyword={searchInput} totalData={}/>*!/*/}
          {/*    </Widget>*/}
          {/*  </Grid>*/}
          {/*</TabPanel>*/}
        </TabContext>
      </Box>
    </div>
  );
}

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 24px;
    width: 20%;
`