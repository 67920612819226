import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Typography } from "@material-ui/core";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Widget from "../../../components/Widget";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import U from "../../../styled-components/userTableStyle"
import UserTablePopup from "./UserTablePopup";

function UserTable() {
  const [memberSelectedValue, setMemberSelectedValue] = useState(2);
  const [subMemberList, setSubMemberList] = useState(null);  // 초기값을 null로 설정
  const [isLoading, setIsLoading] = useState(true);  // 로딩 상태 추가
  
  // 오늘 날짜
  const today = new Date();
  const todayFormat = dayjs(today).endOf('day'); // 시간을 23:59:59로 설정
  const [memberStartDateValue, setMemberStartDateValue] = useState(todayFormat);

  // 증가 회원 정보 팝업
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectHead, setSelectHead] = useState(null);
  const [selectType, setSelectType] = useState(null);

  function mergeData(arr) {
    let resultObject = {
      past: [],
      now: [],
    };

    arr.forEach((item) => {
      const dateArray = item[0]; // 날짜
      const totalMemberArray = item[1]; // 전체 회원 (기관별)
      const planMemberArray = item[2]; // 플랜 회원 (기관별)

      // 객체의 키 목록을 가져옴
      const keys = Object.keys(totalMemberArray[5]).filter(key => key !== "전체합");

      // 합계를 저장할 변수 초기화
      let pastTotal = { totalMember: 0, subscribeMember: 0, normalMember: 0 };
      let nowTotal = { totalMember: 0, subscribeMember: 0, normalMember: 0 };

      keys.forEach((key) => {
        for (let i of [5, 6]) {
          const totalMember = totalMemberArray[i][key] || 0; // totalMember 값
          const subscribeMember = planMemberArray[i][key] || 0; // subscribeMember 값

          let ret = {
            date: dateArray[i].date, // 날짜는 배열의 해당 인덱스 값
            category: key, // 현재 카테고리
            totalMember: totalMember,
            subscribeMember: subscribeMember,
            normalMember: totalMember - subscribeMember, // 계산
          };

          // i 값에 따라 past 또는 now에 추가
          if (i === 5) {
            resultObject.past.push(ret);
            pastTotal.totalMember += totalMember;
            pastTotal.subscribeMember += subscribeMember;
            pastTotal.normalMember += totalMember - subscribeMember;
          } else if (i === 6) {
            resultObject.now.push(ret);
            nowTotal.totalMember += totalMember;
            nowTotal.subscribeMember += subscribeMember;
            nowTotal.normalMember += totalMember - subscribeMember;
          }
        }
      });

      // 합계 행 추가
      resultObject.past.push({
        date: dateArray[5].date,
        category: "합계",
        ...pastTotal,
      });

      resultObject.now.push({
        date: dateArray[6].date,
        category: "합계",
        ...nowTotal,
      });
    });

    return resultObject;
  }



  useEffect(() => {
    if (memberStartDateValue) {
      axios
        .get(
          "/api/member/memberGraph?term=" +
          memberSelectedValue +
          "&startDate=" +
          memberStartDateValue,
        )
        .then((response) => {
          const memberData = mergeData([response.data.data]);
          setSubMemberList(memberData);
          setIsLoading(false);  // 데이터 로딩 완료
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);  // 오류 발생 시에도 로딩 상태 종료
        });
    }
  }, [memberStartDateValue, memberSelectedValue]);

  const onClickChangeCnt = (head, orgType, value) => {

    if (value <= 0) {
      return;  // value가 0 이하일 경우 조건 부적합
    }

    setSelectHead(head);
    setSelectType(orgType);

    setPopupOpen(true);
  }

  const memberValueChange = (event) => {
    setMemberSelectedValue(event.target.value);
  };

  // 로딩 상태일 때는 테이블을 렌더링하지 않도록 처리
  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Widget>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              회원 증감 비교
            </Typography>
            <Grid style={{ marginLeft: 45 }}>
              <select
                className="input_style"
                onChange={memberValueChange}
                value={memberSelectedValue}
              >
                <option value={1}>Day</option>
                <option value={2}>Week</option>
                <option value={3}>Month</option>
              </select>
            </Grid>
          </div>
          <Grid container spacing={1}>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                dateFormats={{ monthShort: "M" }}
                adapterLocale="ko"
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="기준 날짜 선정"
                    value={memberStartDateValue}
                    onChange={(event) => setMemberStartDateValue(event)}
                    shouldDisableDate={(day) => {
                      const datePickerToday = dayjs().startOf("day");
                      return dayjs(day).isAfter(datePickerToday, "day");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Grid>

          {subMemberList && (
            <U.TableContainer>
              <U.StyledTable>
                <thead>
                  <tr>
                    <th rowSpan="2">기관</th>
                    <th colSpan="3">전체 회원</th>
                    <th colSpan="3">플랜 회원</th>
                  </tr>
                  <tr>
                    {["전체 회원", "플랜 회원"].map((category, index) => (
                      <>
                        <th key={`past-${category}-${index}`}>{subMemberList.past[0].date}</th>
                        <th key={`now-${category}-${index}`}>{subMemberList.now[0].date}</th>
                        <th key={`change-${category}-${index}`}>증감</th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {subMemberList.past.map((pastItem, index) => {
                    const nowItem = subMemberList.now[index];

                    return (
                      <tr key={pastItem.category}>
                        <th>{pastItem.category}</th>

                        {/* 전체 회원 */}
                        <td>{(pastItem.totalMember).toLocaleString()}</td>
                        <td>{(nowItem.totalMember).toLocaleString()}</td>
                        <U.VariationTD
                          onClick={() => (
                            onClickChangeCnt('전체 회원', pastItem.category, nowItem.totalMember - pastItem.totalMember)
                          )}
                          value={nowItem.totalMember - pastItem.totalMember}
                        >
                          {(nowItem.totalMember - pastItem.totalMember).toLocaleString()}
                        </U.VariationTD>

                        {/* 플랜 회원 */}
                        <td>{(pastItem.subscribeMember).toLocaleString()}</td>
                        <td>{(nowItem.subscribeMember).toLocaleString()}</td>
                        <U.VariationTD
                          onClick={() => (
                            onClickChangeCnt('플랜 회원', pastItem.category, nowItem.subscribeMember - pastItem.subscribeMember)
                          )}
                          value={
                            nowItem.subscribeMember - pastItem.subscribeMember
                          }
                        >
                          {(nowItem.subscribeMember - pastItem.subscribeMember).toLocaleString()}
                        </U.VariationTD>
                      </tr>
                    );
                  })}
                </tbody>
              </U.StyledTable>
            </U.TableContainer>
          )}
        </Widget>
      </Grid>

      {popupOpen ? (
        <UserTablePopup
          closePopup={() => setPopupOpen(false)}
          head={selectHead}
          orgType={selectType}
          memberStartDateValue={memberStartDateValue}
          memberSelectedValue={memberSelectedValue}
          startDate={subMemberList?.past[0].date}
          endDate={subMemberList?.now[0].date}
        />
      ) : null}
    </>
  );
}

export default UserTable;
