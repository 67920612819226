import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider
} from "@material-ui/core";
import Q from "../../../styled-components/qnaPopupStyle"
import PU from "../../../styled-components/popupBasicStyle";

function changeData(array, index1){
  array.forEach((item) => {
    const value1 = item[index1];
    item[index1] = "C:\\TD-groupware\\file\\광경화 레진_20240110.xlsx"
  })
  console.log(array);
  return array;
}

export default function DirectDetailPopUp(props){
  const { open, close, seq } = props;
  const [directDetail, setDirectDetail] = useState();

  // 상세 문의 내용
  useEffect(() => {
    axios.get("/api/memberCenter/directDetail?seq=" + seq)
      .then((response)=>{
        setDirectDetail(changeData([...response.data.data], "file_path").reverse());
      })
      .catch((error) => {
        console.error('Error MileagePopUp checkSubscription axios request:', error);
      })
  }, [seq]);

  return (
    <>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              boxShadow: "none",
              padding: 30,
            },
          }}
        >
          <div>
            <DialogContent>
              {Array.isArray(directDetail) &&
                directDetail.map((item) => (
                  <DialogContentText>
                      <Q.CloseDiv>
                        <Q.CloseButton onClick={close}>
                          <PU.Line />
                          <PU.Line />
                        </Q.CloseButton>
                      </Q.CloseDiv>
                      <Q.Header>
                        <Q.Title>1:1 문의 내용 상세</Q.Title>
                      </Q.Header>
                    <Divider/>
                    <h3>기본 정보</h3>
                    <Q.ConTent style={{ width: "100%" }}>
                      <Q.divTitle style={{ marginRight: "25px" }}>제목 </Q.divTitle>
                      <Q.Paper style={{ width: "95.4%" }}>
                        {item.title}
                      </Q.Paper>
                    </Q.ConTent>
                    <h3>담당자 정보</h3>
                    <Q.ConTent style={{ margin: "10px 0" }}>
                      <Q.ConTent style={{ width: "50%"}}>
                        <Q.divTitle style={{ marginRight: "26px"}}>이름</Q.divTitle>
                        <Q.Paper style={{ width: "78%" }}>
                          {item.member_name}
                        </Q.Paper>
                      </Q.ConTent>
                      <Q.ConTent style={{ width: "50%"}}>
                        <Q.divTitle style={{ marginRight: "43px"}}>기관(회사)명</Q.divTitle>
                        <Q.Paper style={{ width: "78.1%" }}>
                          {item.org_name}
                        </Q.Paper>
                      </Q.ConTent>
                    </Q.ConTent>
                    <Q.ConTent>
                      <Q.ConTent style={{ width: "50%"}}>
                        <Q.divTitle style={{ marginRight: "10px" }}>이메일</Q.divTitle>
                        <Q.Paper style={{ width: "78%" }}>
                          {item.member_email}
                        </Q.Paper>
                      </Q.ConTent>
                      {item.tel && (
                        <Q.ConTent style={{ width: "50%"}}>
                          <Q.divTitle style={{ marginRight: "10px" }}>연락처(전화번호)</Q.divTitle>
                          <Q.Paper style={{ width: "78.2%"}}>
                            {item.tel}
                          </Q.Paper>
                        </Q.ConTent>
                      )}
                    </Q.ConTent>
                    <h3>문의 내용</h3>
                    <Q.ConTent2 style={{ margin: "10px 0" }}>
                      <Q.divTitle style={{ marginBottom: "5px" }}>문의 내용</Q.divTitle>
                      <Q.Paper style={{ width: "100%"}}>
                        {item.content}
                      </Q.Paper>
                    </Q.ConTent2>
                    {item.file_name && (
                      <Q.ConTent2>
                        <Q.divTitle style={{ marginBottom: "5px" }}>첨부 파일</Q.divTitle>
                        <Q.Paper style={{ width: "100%" }}>
                          <a href={item.file_path}>{item.file_name}</a>
                        </Q.Paper>
                      </Q.ConTent2>
                    )}
                  </DialogContentText>
                ))}
            </DialogContent>
          </div>
        </Dialog>
      ) : null}
    </>
  );
}