import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import ResearchDashboard from "../../pages/ResearchALL_Dashboard/ResearchDashboard";
import Member from "../../pages/Member/Member";
import JoinNew from "../../pages/Member/join_new";
import Product from "../../pages/Product/Product";
import Payment from "../../pages/Payment/Payment";
import PGPayment from "../../pages/PG_Payment/PGPayment";
import MemberCenter from "../../pages/MemberCenter/MemberCenter";
import Project from "../../pages/Project/Project";
import Applicant from "../../pages/Applicant/Applicant";
import Register from "../../pages/MemberCenter/components/Register";
import Plan from "../../pages/Plan/Plan"
import CreatePlan from "../../pages/Plan/CreatePlan";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
              <Route path="/app/researchAll/researchdashboard" component={ResearchDashboard} />
              <Route path="/app/researchAll/member" exact component={Member} />
              <Route path="/app/researchAll/member/joinNew" exact component={JoinNew} />
              <Route path= "/app/researchAll/product" component={Product} />
              <Route path= "/app/researchAll/payment" component={Payment} />
              <Route path= "/app/researchAll/PGpayment" component={PGPayment} />
              <Route path= "/app/researchAll/membercenter" component={MemberCenter} />
              <Route path= "/app/researchAll/project" component={Project} />
              <Route path= "/app/researchAll/applicant" component={Applicant} />
              <Route path= "/app/researchAll/help/register" component={Register} />
              <Route path="/app/researchAll/plan" exact component={Plan} />
              <Route path="/app/researchAll/plan/createPlan" exact component={CreatePlan} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://techdna.kr/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  TechDNA
                </Link>
                <Link
                  color={'primary'}
                  href={'https://techdna.kr/aboutus?category=vision'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://blog.naver.com/techdna'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
