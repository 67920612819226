import React from "react";
import axios from "axios"

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE": // 로그인 실패 처리
      return { ...state, isAuthenticated: false, error: action.error };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

const getMemberSeq = async (loginEmail) => {

  const email = loginEmail;

  try {
    // API 호출
    const response = await axios.get('/api/plan/getMemberSeq', {
      params: { email }, // 쿼리 파라미터 전달
    });

    const status = response.status;
    const memberSeq = response.data.data;

    console.log(response);
    if (status === 200) {
      localStorage.setItem('memberSeq', memberSeq);
      console.log('memberSeq', memberSeq);
    }
  }
  catch (error) {
    console.log("회원 번호 조회에 실패하였습니다.");
  }
};

async function loginUser(responseData, responseToken, dispatch, history, setIsLoading, setError, loginValue) {
  setError(false);
  setIsLoading(true);

  if (responseData) {
    setTimeout(async () => { // setTimeout 내에서 async 사용
      localStorage.setItem('id_token', responseToken);

      try {
        await getMemberSeq(loginValue); // `await`로 API 요청이 완료될 때까지 기다림
      } catch (error) {
        console.error("회원 번호 조회 실패:", error);
      }

      setError(null);
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS' });

      history.push('/app'); // getMemberSeq() 완료 후 페이지 이동
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}


function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("memberSeq");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
