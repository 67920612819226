import React, { useEffect, useState } from "react";
import axios from "axios";

// 전역 상태 관리
import { useRecoilState } from "recoil";
import { dataChangeState } from "../../../recoil/changePlanData";

import PU from "../../../styled-components/popupBasicStyle";

function EditContextPopup ({ closePopup, paidSeq, paidNote, editPart, prevData }) {

  // reload를 위한 전역 상태 변수
  const [, setDataChange] = useRecoilState(dataChangeState);

  const [step, setStep] = useState("기본");
  const [inputType, setInputType] = useState("text");
  const [inputPattern, setInputPattern] = useState("");
  const [inputMaxlength, setInputMaxlength] = useState(100);
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [inputValue, setInputValue] = useState(""); // 사용자 입력값
  const [isValid, setIsValid] = useState(true); // 유효성 상태
  const [errorMessage, setErrorMessage] = useState("");

  // 팝업 닫기
  const handleClosePopup = () => {
    setStep("기본");
    setInputType("text");
    setInputPattern("");
    setInputMaxlength(100);
    setInputPlaceholder("");
    setInputValue("");
    setIsValid(true);
    setErrorMessage("");

    closePopup();
  };

  // innerTotalDiv 클릭 시 팝업 닫히지 않도록
  const handleInnerClick = (e) => {
    e.stopPropagation(); // 부모 컴포넌트로 이벤트 전파를 막음
  };

  // 스크롤
  useEffect(() => {
    document.body.style.overflow = "hidden"; // 스크롤 막기
    return () => {
      document.body.style.overflow = "auto"; // 스크롤 복구
    };
  }, []);

  // input option
  useEffect(() => {
    switch (editPart) {

      case "상품명":
        setInputType("text");
        setInputPattern(".{1,100}");
        setInputMaxlength(100);
        setInputPlaceholder("상품명을 입력해주세요");
        break;

      case "가격":
        setInputType("text");
        setInputPattern("\\d{1,11}");
        setInputMaxlength(11);
        setInputPlaceholder("최대 11자리 숫자만 입력해주세요");
        break;

      case "종료일":
        setInputType("date");
        setInputPattern("");
        setInputMaxlength(null);
        setInputPlaceholder("");
        break;

      case "담당자":
        setInputType("text");
        setInputPattern(".{1,10}");  // 10자까지 입력 가능한 패턴
        setInputMaxlength(10);
        setInputPlaceholder("10자 내로 입력해주세요");
        break;

      case "담당자 이메일":
        setInputType("email");
        setInputPattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$");
        setInputMaxlength(100);
        setInputPlaceholder("example@domain.com");
        break;

      case "담당자 연락처":
        setInputType("tel");
        setInputPattern("\\d{2,3}-\\d{3,4}-\\d{4}");  // 연락처 11자리 숫자 패턴
        setInputMaxlength(13);
        setInputPlaceholder("010-0000-0000");
        break;

      case "특이사항":
        setInputType("text");
        setInputPattern(".{1,100}");  // 특이사항은 별도의 패턴 없음
        setInputMaxlength(100);  // 특이사항은 100자까지 입력 가능
        setInputPlaceholder("특이사항");
        break;

      default:
        setInputType("text");
        setInputPattern(".{1,100}");
        setInputMaxlength(100);
        break;
    }
  }, [editPart]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsValid(true); // 입력 중에는 유효성 오류 초기화
  };

  // 완료 버튼 클릭 시 유효성 검사
  const handleButtonClick = () => {

    if (inputPattern) {
      const regex = new RegExp(`^${inputPattern}$`); // 전체 매칭 검사
      const valid = regex.test(inputValue);
      setIsValid(valid);

      if (!valid) {
        setErrorMessage("입력이 유효하지 않습니다.");
      }
      else {
        editPlanContext();
      }
    }
    else if (editPart === "종료일") {
      console.log('종료일 수정 test :', inputValue);
      editPlanContext();
    }
  };

  // 컬럼 내용 수정
  const editPlanContext = async () => {
    try {
      // 필수 데이터 확인
      if (!editPart || !inputValue || !paidSeq) {
        throw new Error('모든 필수 데이터를 입력해야 합니다.');
      }

      // 요청 데이터 준비
      const body = {
        edit_part: editPart,
        context: inputValue,
        paid_seq: paidSeq,
      };

      // 서버 요청
      const response = await axios.post('/api/plan/editPlanContext', body);

      // 응답 처리
      if (response.status === 200) {
        console.log('수정 성공:', response.data.msg);
        setStep("수정 성공");
        setDataChange(true);
      } else {
        console.error('수정 실패:', response.data.msg);
        setStep("실패");
      }
    }
    catch (error) {
      // 에러 처리
      console.error('에러 발생:', error.message);
      setStep("실패");
    }
  };

  // 삭제 버튼 클릭 시
  const handleDeleteBtnClick = () => {
    deletePlanContext()
  }
  
  // 컬럼 내용 삭제
  const deletePlanContext = async () => {
    try {
      // 필수 데이터 확인
      if (!editPart || !paidSeq) {
        throw new Error('모든 필수 데이터를 입력해야 합니다.');
      }

      // 요청 데이터 준비
      const body = {
        edit_part: editPart,
        paid_seq: paidSeq,
      };

      if (prevData === undefined || prevData === null || prevData === 0) {
        setStep("삭제 불가"); // 삭제할 데이터 없음
        return
      }

      // 서버 요청
      const response = await axios.post('/api/plan/deletePlanContext', body);

      // 응답 처리
      if (response.status === 200) {
        console.log('삭제 성공:', response.data.msg);
        setStep("삭제 성공");
        setDataChange(true);
      } else {
        console.error('삭제 실패:', response.data.msg);
        setStep("실패");
      }
    }
    catch (error) {
      // 에러 처리
      console.error('에러 발생:', error.message);
      setStep("실패");
    }
  };

  useEffect(() => {
    console.log("editPart :",editPart);
    if(["상품명", "종료일"].includes(editPart)){
      console.log("if :",editPart);
      setStep("삭제 불가 기본");
    }
  }, []);

  return (
    <PU.TotalDiv onClick={handleClosePopup}>
      <PU.InnerTotalDiv onClick={handleInnerClick}>

        <PU.CloseDiv>
          <PU.CloseButton onClick={handleClosePopup}>
            <PU.Line/>
            <PU.Line/>
          </PU.CloseButton>
        </PU.CloseDiv>

        <PU.ContentDiv>

          {/*상단까지 기본 컴포넌트*/}

          {step === "기본" && (
            <>
              <PU.TitleH1>플랜 변경</PU.TitleH1>
              <PU.DescriptionP>
                <PU.BoldSpan>{paidNote} 플랜</PU.BoldSpan>의<br />
                <PU.BoldSpan>{editPart} 내용</PU.BoldSpan>을 변경합니다.
              </PU.DescriptionP>
              <PU.BtnDiv>
                <PU.Step1Btn onClick={() => setStep("수정")}>내용 수정</PU.Step1Btn>
                <PU.Step1Btn onClick={() => setStep("삭제")}>내용 삭제</PU.Step1Btn>
              </PU.BtnDiv>
            </>
          )}

          {step === "삭제 불가 기본" && (
            <>
              <PU.TitleH1 >플랜 변경</PU.TitleH1>
              <PU.DescriptionP>
                <PU.BoldSpan>{paidNote} 플랜</PU.BoldSpan>의<br />
                <PU.BoldSpan>{editPart} 내용</PU.BoldSpan>을 수정합니다.
              </PU.DescriptionP>

              <PU.InputDiv>
                <PU.Input
                  type={inputType}
                  pattern={inputPattern}
                  maxlength={inputMaxlength}
                  placeholder={inputPlaceholder}
                  value={inputValue}
                  onChange={handleInputChange}
                ></PU.Input>
                <PU.Step1Btn onClick={handleButtonClick}>입력</PU.Step1Btn>
              </PU.InputDiv>

              <PU.WarningH6>{errorMessage}</PU.WarningH6>

            </>
          )}

          {step === "삭제" && (
            <>
              <PU.TitleH1>{editPart} 삭제</PU.TitleH1>
              <PU.DescriptionP>
                <PU.BoldSpan>{paidNote} 플랜</PU.BoldSpan>의<br/><PU.BoldSpan>{editPart} 내용</PU.BoldSpan>을 삭제하시겠습니까?
              </PU.DescriptionP>
              <PU.BtnDiv>
                <PU.NoBtn onClick={() => {setStep("기본")}}>취소</PU.NoBtn>
                <PU.YesBtn onClick={handleDeleteBtnClick}>삭제</PU.YesBtn>
              </PU.BtnDiv>
            </>
          )}

          {step === "삭제 성공" && (
            <>
              <PU.TitleH1>삭제 되었습니다.</PU.TitleH1>
            </>
          )}

          {step === "삭제 불가" && (
            <>
              <PU.TitleH1>삭제할 내용이 없습니다.</PU.TitleH1>
            </>
          )}

          {step === "수정" && (
            <>
              <PU.TitleH1>{editPart} 수정</PU.TitleH1>
              <PU.DescriptionP>
                내용을 수정해주세요.
              </PU.DescriptionP>

              <PU.InputDiv>
                <PU.Input
                  type={inputType}
                  pattern={inputPattern}
                  maxlength={inputMaxlength}
                  placeholder={inputPlaceholder}
                  value={inputValue}
                  onChange={handleInputChange}
                ></PU.Input>
                <PU.Step1Btn onClick={handleButtonClick}>입력</PU.Step1Btn>
              </PU.InputDiv>

              <PU.WarningH6>{errorMessage}</PU.WarningH6>

              <PU.NoBtn onClick={() => {
                setInputType("text")
                setInputPattern("")
                setInputMaxlength(100)
                setInputPlaceholder("")
                setInputValue("")
                setIsValid(true)
                setErrorMessage("")
                setStep("기본")
              }}>뒤로</PU.NoBtn>
            </>
          )}

          {step === "수정 성공" && (
            <>
              <PU.TitleH1>수정 되었습니다.</PU.TitleH1>
            </>
          )}

          {step === "실패" && (
            <>
              <PU.TitleH1>실패</PU.TitleH1>
              <PU.DescriptionP>
                <PU.BoldSpan>{editPart} 내용</PU.BoldSpan>을 변경에 실패했습니다.
                <br/>다시 시도해주세요.
              </PU.DescriptionP>
            </>
          )}

          {/*하단부터 기본 컴포넌트*/}
        </PU.ContentDiv>
      </PU.InnerTotalDiv>
    </PU.TotalDiv>
  )

}

export default EditContextPopup;