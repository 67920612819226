// 리서치 올 대시 보드 페이지
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ko'
import {
  Cell,
  Pie,
  PieChart,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import ServiceStatusTable from "./components/ServiceStatusTable";
import Sales from "./components/Sales";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import axios from "axios";
import NewPlanMemTable from "./components/NewPlanMemTable";
import dayjs from 'dayjs'
import UserTable from "./components/userTable";
import WeeklyInquiry from "./components/WeeklyInquiry";
import ExpiringPlanMembers from "./components/ExpiringPlanMembers";


// 전체회원 org_type 데이터 머지
function mergeData(arr){
  let arr4 = [];
  arr.forEach((item) => {
    const arr1 = item[0];
    const arr2 = item[1];
    const arr3 = item[2];
    for(let i=0; i<7; i++){
      let ret = {
        date: arr1[i],
        totalMember: arr2[i],
        subscribeMember: arr3[i]
      }
      arr4.push(ret);
    }
  })
  return arr4;
}

//프로젝트 그래프 커스텀 툴팁
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div
        style={{
          padding: "6px",
          backgroundColor: "white",
          border: "1px solid grey"
        }}
      >
        <b style={{ marginBottom: "10px"}}>{payload[0].payload.date.date}</b>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            <b>전체 회원: {payload[0].payload.totalMember.공공기관 + payload[0].payload.totalMember.기업 + payload[0].payload.totalMember.연구소 + payload[0].payload.totalMember.특허법인 + payload[0].payload.totalMember.학교 + payload[0].payload.totalMember.기타}</b>
            <p>공공기관: {payload[0].payload.totalMember.공공기관}</p>
            <p>기업: {payload[0].payload.totalMember.기업}</p>
            <p>연구소: {payload[0].payload.totalMember.연구소}</p>
            <p>특허법인: {payload[0].payload.totalMember.특허법인}</p>
            <p>학교: {payload[0].payload.totalMember.학교}</p>
            <p>기타: {payload[0].payload.totalMember.기타}</p>
          </div>
          <div>
            <b>플랜 회원: {payload[0].payload.subscribeMember.공공기관 + payload[0].payload.subscribeMember.기업 + payload[0].payload.subscribeMember.연구소 + payload[0].payload.subscribeMember.특허법인 + payload[0].payload.subscribeMember.학교 + payload[0].payload.subscribeMember.기타}</b>
            <p>공공기관: {payload[0].payload.subscribeMember.공공기관}</p>
            <p>기업: {payload[0].payload.subscribeMember.기업}</p>
            <p>연구소: {payload[0].payload.subscribeMember.연구소}</p>
            <p>특허법인: {payload[0].payload.subscribeMember.특허법인}</p>
            <p>학교: {payload[0].payload.subscribeMember.학교}</p>
            <p>기타: {payload[0].payload.subscribeMember.기타}</p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

function ResearchDashboard(){
  const today = new Date();
  const todayFormat = dayjs(today).endOf('day'); // 시간을 23:59:59로 설정

  const [memberStartDateValue,setMemberStartDateValue] = useState(todayFormat);
  const [projectStartDateValue, setProjectStartDateValue] = useState(todayFormat);
  // 회원 수 그래프 select value
  const [memberSelectedValue, setMemberSelectedValue] = useState(1);
  // 프로젝트 수 그래프 select value
  const [projectSelectedValue, setProjectSelectedValue] = useState(2);
  const [projectGraphList, setProjectGraphList] = useState();
  const [subMemberList, setSubMemberList] = useState();
  const [newPlanMemberList, setNewPlanMemberList] = useState();
  const [yearPaymentDataKey, setYearPaymentDataKey] = useState([]);
  const [monthGradeMember, setMonthGradeMember] = useState([]);
  const [dailyGradeMember, setDailyGradeMember] = useState([]);

  const COLORS = [
    "#FF5E98", // 핑크
    "#4C97E8", // 파랑
    "#9076CB", // 보라
    "#7CCB76", // 연두
    "#FF8962", // 오렌지
    "#FFB84D", // 황금색
    "#52D8A8", // 민트
    "#FF6F61", // 진한 핑크
    "#8D6E63", // 갈색
    "#2C6E93", // 파란색
    "#F5C300", // 노랑
    "#66BB6A", // 초록
  ];

  // 전체 회원 org_type
  useEffect(() => {
    if(memberStartDateValue){
      axios.get("/api/member/memberGraph?term=" + memberSelectedValue + "&startDate=" + memberStartDateValue)
        .then((response) => {
          const memberData = mergeData([response.data.data])
          setSubMemberList(memberData);

          const planMemData = [];
          planMemData.push(
            memberData[5]?.subscribeMember.전체합, // 어제 플랜 회원 수
            memberData[6]?.subscribeMember.전체합, // 오늘 플랜 회원 수
            memberData[6]?.subscribeMember.전체합 - memberData[5]?.subscribeMember.전체합); // 플랜 회원 수 증감
        })
    }

  },[memberStartDateValue, memberSelectedValue])

  // 프로젝트 그래프 api
  useEffect(() => {
    if(projectStartDateValue){
      axios.get("/api/projects/projectGraph?term=" + projectSelectedValue + "&startDate=" + projectStartDateValue)
        .then((response) => {
          // console.log(response)
          setProjectGraphList(response.data.data)
        })
    }
  },[projectStartDateValue, projectSelectedValue])

  // 등급 별 회원 수(주) api
  useEffect(() => {
    axios.get("/api/member/gradeMemberWeek")
      .then((response) => {
        const gradeMemAPIData = response.data.data;
        const dailyData = [];
        const keys = [];

        for (let i = 5; i >= 1; i--) {
          const weekData = {
            date: i - 1 + " weeks ago"
          };

          // gradeMemAPIData 길이에 맞춰서 동적으로 key를 추가
          gradeMemAPIData.forEach((data, index) => {
            const gradeName = data.grade_name;
            const sumValue = data[`sum(w${i})`];
            weekData[gradeName] = sumValue;

            // keys 배열에 중복 없이 grade_name 추가
            if (!keys.includes(gradeName)) {
              keys.push(gradeName);
            }
          });

          dailyData.push(weekData);
        }

        setMonthGradeMember(dailyData);
        setYearPaymentDataKey(keys);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // 신규 플랜 회원 목록 api
  useEffect(() => {
    axios.get("/api/member/newPlanMemList")
      .then((response) => {
        // console.log(response.data.data)
        setNewPlanMemberList(response.data.data)
    })
  }, [])

  // 등급별 구독 회원 수(당일)api
  useEffect(() => {
    axios.get("/api/member/gradeMemberDaily")
      .then((response) => {
        // console.log(response.data.data)
        const gradeMemAPIData = response.data.data;
        const dailyData = [];

        // length 속성은 배열에서 바로 사용
        for (let i = 0; i < gradeMemAPIData.length; i++) {  // 배열의 인덱스는 0부터 시작
          if (gradeMemAPIData[i].value > 0) {
            dailyData.push(gradeMemAPIData[i]);
          }
        }

        setDailyGradeMember(dailyData);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // 회원수 월/년/일 선택 변경
  const memberValueChange = (event) => {
    setMemberSelectedValue(event.target.value)
  }
  // 프로젝트 수 월/년/일 선택 변경
  const projectValueChange = (event) => {
    setProjectSelectedValue(event.target.value)
  }

  return (
    <div>
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        <ServiceStatusTable />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <div style={{ display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                총 회원 수
              </Typography>
              <Grid style={{ marginLeft: 82 }}>
                <select
                  className="input_style"
                  onChange={memberValueChange}
                  value={memberSelectedValue}
                >
                  <option value={1}>Day</option>
                  <option value={2}>Week</option>
                  <option value={3}>Month</option>
                </select>
              </Grid>
            </div>
            <Grid container spacing={1}>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthShort: "M" }}
                  adapterLocale="ko"
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      label="Controlled picker"
                      value={memberStartDateValue}
                      onChange={(event) => setMemberStartDateValue(event)}
                      shouldDisableDate={(day) => {
                        const datePickerToday = dayjs().startOf("day");
                        return dayjs(day).isAfter(datePickerToday, "day");
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {subMemberList && (
                <ResponsiveContainer width="100%" height={350} debounce={300}>
                  <BarChart data={subMemberList}>
                    <XAxis dataKey="date.date" />
                    <YAxis />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.공공기관"
                      fill="#FF5E98"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.기업"
                      fill="#4C97E8"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.연구소"
                      fill="#9076CB"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.특허법인"
                      fill="#7CCB76"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.학교"
                      fill="#FF8962"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="totalMember"
                      dataKey="totalMember.기타"
                      fill="#FFB84D"
                      label={{ fill: "#ffffff" }}
                    />

                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.공공기관"
                      fill="#FF5E98"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.기업"
                      fill="#4C97E8"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.연구소"
                      fill="#9076CB"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.특허법인"
                      fill="#7CCB76"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.학교"
                      fill="#FF8962"
                      label={{ fill: "#ffffff" }}
                    />
                    <Bar
                      type="monotone"
                      stackId="subscribeMember"
                      dataKey="subscribeMember.기타"
                      fill="#FFB84D"
                      label={{ fill: "#ffffff" }}
                    />
                    <Legend
                      payload={[
                        { value: "공공기관", id: "공공기관", color: "#FF5E98" },
                        { value: "기업", id: "기업", color: "#4C97E8" },
                        { value: "연구소", id: "연구소", color: "#9076CB" },
                        { value: "특허법인", id: "특허법인", color: "#7CCB76" },
                        { value: "학교", id: "학교", color: "#FF8962" },
                        { value: "기타", id: "기타", color: "#FFB84D" },
                      ]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Grid>
          </Widget>
        </Grid>

        {/*회원 증감 비교*/}
        <UserTable />

        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Widget>
            <Grid container spacing={1}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                등급 별 플랜 회원 수
              </Typography>
              <ResponsiveContainer
                width="100%"
                height={350}
                debounce={300}
                style={{
                  position: "relative",
                  marginTop: "85px",
                  right: "20px",
                }}
              >
                <LineChart data={monthGradeMember}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {yearPaymentDataKey.map((dataKey, index) => (
                    <Line
                      key={dataKey}
                      dataKey={dataKey}
                      stroke={COLORS[index % COLORS.length]}
                      fill={COLORS[index % COLORS.length]}
                      strokeWidth={2}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Widget>
            <Grid container spacing={1}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                등급 별 플랜 회원 비율
              </Typography>
              <ResponsiveContainer width="100%" height={300} debounce={300}>
                <PieChart>
                  <Pie
                    data={dailyGradeMember}
                    innerRadius={90}
                    outerRadius={130}
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {dailyGradeMember.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Grid style={{ positionRight: 0 }}>
                {dailyGradeMember.map(({ name, value }, index) => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    <div
                      style={{
                        width: "10px", // 필요에 따라 너비와 높이 조절
                        height: "10px",

                        position: "relative",
                        top: "40px",
                        backgroundColor: COLORS[index % COLORS.length],
                        marginRight: "5px", // 간격을 위한 마진 추가
                      }}
                    />
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: 14,
                        color: COLORS[index % COLORS.length],
                        fontWeight: "bold",

                        position: "relative",
                        top: "40px",
                      }}
                    >
                      {name}:&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: 14,
                        color: COLORS[index % COLORS.length],
                        fontWeight: "bold",

                        position: "relative",
                        top: "40px",
                      }}
                    >
                      &nbsp;{value}명&nbsp;&nbsp;
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Widget>
            <div style={{ display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                특허 프로젝트 수
              </Typography>
              <Grid style={{ marginLeft: 50 }}>
                <select
                  className="input_style"
                  onChange={projectValueChange}
                  value={projectSelectedValue}
                >
                  <option value={1}>Day</option>
                  <option value={2}>Week</option>
                  <option value={3}>Month</option>
                </select>
              </Grid>
            </div>
            <Grid container spacing={1}>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthShort: "M" }}
                  adapterLocale="ko"
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      label="Controlled picker"
                      value={projectStartDateValue}
                      onChange={(event) => setProjectStartDateValue(event)}
                      shouldDisableDate={(day) => {
                        return dayjs(day).isAfter(today, "day");
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <ResponsiveContainer
                width="100%"
                height={350}
                debounce={300}
                style={{ position: "relative", right: "20px" }}
              >
                <BarChart data={projectGraphList}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    type="monotone"
                    dataKey="project"
                    fill="#8884d8"
                    label={{ fill: "#ffffff" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Widget>
        </Grid>
        <Sales />

        {/* 이번주 1:1 문의 */}
        <WeeklyInquiry/>

        {/* 플랜 종료 예정 회원 */}
        <ExpiringPlanMembers />

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              신규 플랜 회원 (일주일 기준)
            </Typography>
            <Grid container spacing={1} style={{ display: "contents" }}>
              <NewPlanMemTable totalData={newPlanMemberList} />
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResearchDashboard;