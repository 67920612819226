import styled from 'styled-components';
import { RiArrowUpDownLine } from "react-icons/ri";

const D = {

  TotalTopDiv: styled.div`
      position: relative;
      left: 15px;

      padding: 0px 10px 20px 10px;

      width: 98.2%;

      background-color: white;
      border-radius: 3px;
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
  `,

  BoxNameH6: styled.h6`
      font-size: 1.142rem;
      font-weight: bold;
      
      margin: 20px 12px;
  `,

  TopDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      padding: 0px 10px;
  `,

  TopInnerDiv: styled.div`
      width: 15%;
  `,

  CategoryName: styled.h2`
      font-weight: bold;
      font-size: 40px;
      
      margin: 0px 0px;
  `,

  CategoryCnt: styled.span`
      font-weight: bold;
      font-size: 20px;
  `,

  CategoryNumDiv: styled.div`
      display: flex;
  `,
}

export default D;
