import React, { useEffect, useState } from 'react';
import Widget from "../../../components/Widget";
import { Grid, Typography } from "@material-ui/core";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axios from 'axios';

function Sales() {
  const [paymentData, setPaymentData] = useState({
    week: [],
    month: [],
    year: [],
  });

  const [selectedValue, setSelectedValue] = useState(2);

  // 매출 선택값 변경
  const paymentValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const formatYAxis = (yTick) => {
    return `${yTick.toLocaleString()}`;
  };

  const formatToolTip = (value) => {
    return `${value.toLocaleString()}`;
  };

// API 호출 후 데이터 저장
  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const weekResponse = await axios.get("/api/payment/weekPayment");
        const monthResponse = await axios.get("/api/payment/totalMonthPayment");
        const yearResponse = await axios.get("/api/payment/totalYearPayment");

        setPaymentData({
          week: weekResponse.data.data.slice(-6),
          month: monthResponse.data.data.slice(-6),
          year: yearResponse.data.data.slice(-6),
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchPaymentData();
  }, [selectedValue]);

// 선택된 값에 맞는 데이터 반환
  const getPaymentData = () => {
    const value = Number(selectedValue);

    if (value === 1) {
      return paymentData.week;
    } else if (value === 2) {
      return paymentData.month;
    } else if (value === 3) {
      return paymentData.year;
    } else {
      console.log('선택된 값이 없음, 기본값 반환');
      return []; // 예외 상황 방지
    }
  };

  return(
    <>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Widget>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              매출
            </Typography>
            <Grid style={{ marginLeft: 20 }}>
              <select
                className="input_style"
                onChange={paymentValueChange}
                value={selectedValue}
              >
                <option value={1}>Week</option>
                <option value={2}>Month</option>
                <option value={3}>Year</option>
              </select>
            </Grid>
          </div>
          <Grid container spacing={1}>
            <ResponsiveContainer
              width="100%"
              height={350}
              debounce={300}
              style={{ position: "relative", top: "105px", right: "30px" }}
            >
              <BarChart data={getPaymentData()}>
                <XAxis dataKey="date" />
                <YAxis tickFormatter={formatYAxis} width={130} />
                <Tooltip formatter={formatToolTip} />
                <Bar type="monotone" dataKey="Profit" fill="#3943AC" />
                <Bar
                  type="monotone"
                  dataKey="accumulateProfit"
                  fill="#6F8EC7"
                />
                <Legend
                  formatter={(value) => {
                    if (value === "Profit") return "수익";
                    if (value === "accumulateProfit") return "누적 수익";
                    return value;
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Widget>
      </Grid>
    </>
  )
}

export default Sales;