// 1:1 table
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css";
import DirectDetailPopUp from "./DirectDetailPopUp";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter
} from "@material-ui/core";

export default function DirectCenterTable(props){
  const filterKeyword = props.filterKeyword || '';
  const row = props.row;
  const searchTargets = props.searchTargets;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);
  const [seq, setSeq] = useState();

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage=10;
  const handlePageChange = (page) => {
    setPage(page);
  };

  const [detailOpen, setDetailOpen] = useState();
  const detailPopUpOpen = (item) => {
    setDetailOpen(true);
    setSeq(item.bbs_direct_seq);
  }
  const detailPopUpClose = () => {
    setDetailOpen(false);
    // setSeq(null);
  }

  const searchItem = (filterKeyword) => {
    if (filterKeyword !== ''){
      const filteredData = totalData.filter((item) => {
        return searchTargets.some((target) => {
          if (item[target] && typeof item[target] === 'string') {
            return item[target].toLowerCase().includes(filterKeyword.toLowerCase());
          }
        })
      });
      // pagination filter data slice
      setCurrentPost(filteredData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(filteredData.length);
    } else {
      // pagination total data slice
      setCurrentPost(totalData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(totalData.length);
    }
  }

  useEffect(() => {
    searchItem(filterKeyword)
  }, [totalData, page, filterKeyword])


  return(
    <div>
      <TableContainer>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              {row && row.map(headerItem => (
                <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost && currentPost.map(item => (
              <TableRow key={item.num}>
                <TableCell style={{ textAlign: 'center'}}>{item.bbs_direct_seq}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.title}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.member_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.org_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.request_date}</TableCell>
                <TableCell style={{ textAlign: 'center'}}></TableCell>
                <TableCell style={{cursor:"pointer", textAlign: 'center'}} onClick={() => detailPopUpOpen(item)}>보기</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RealTableFooter>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
      <DirectDetailPopUp open={detailOpen} close={detailPopUpClose} seq={seq} />
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`