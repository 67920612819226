import styled from 'styled-components';
import { Button } from "@material-ui/core";

const PU = {
  TotalDiv: styled.div`
      width: 100%;
      height: 100%;
      
      position: fixed;
      top: 0px;
      left: 0px;
      
      z-index: 2000;

      background-color: rgba(0, 0, 0, 0.2);
      
      cursor: default;
  `,

  InnerTotalDiv: styled.div`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 30%;
  `,

  ContentDiv: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
      height: 100%;
  `,

  CloseDiv: styled.div`
      display: flex;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
      
      position: absolute;
      top: 20px;
  `,

  CloseButton: styled.div`
      position: relative;
      right: 20px;
      width: 24px; /* X 버튼 크기 */
      height: 24px;
      cursor: pointer; /* 클릭 가능 표시 */
  `,

  PopupTitle: styled.h1`
      margin: 40px 0px 40px 0px;
      
  `,

  Line: styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%; /* 선 길이 */
      height: 2px; /* 선 두께 */
      background-color: #4A4A4A; /* 선 색상 */
      transform-origin: center;
    
      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg); /* 첫 번째 선 */
      }
    
      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg); /* 두 번째 선 */
      }
  `,

  TitleH1: styled.h1`
      font-size: 28px;
      
      font-weight: normal;
      
      margin-bottom: 20px;
  `,

  BoldSpan: styled.span`
      font-weight: bold;
  `,

  DescriptionP: styled.p`
      font-size: 15px;

      margin-top: 0px;
      margin-bottom: 30px;
      
      text-align: center;
  `,

  BtnDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
  `,

  Step1Btn: styled(Button)`
      background-color: #d3d3d3;
      box-shadow: none;
      
      &:hover{
          background-color: #d3d3d3;
      }
  `,

  NoBtn: styled(Button)`
      background-color: #EA6A6A;
      color: white;
      box-shadow: none;
      
      &:hover{
          background-color: #EA6A6A;
      }
  `,

  YesBtn: styled(Button)`
      background-color: #3085D6;
      color: white;
      box-shadow: none;
      
      &:hover{
          background-color: #3085D6;
      }
  `,

  ExplainP: styled.p`
      font-size: 24px;
      
      margin-top: 0px;
      margin-bottom: 5px;
  `,

  OtherP: styled.p`
      margin-top: 0px;
      margin-bottom: 30px;
      
      font-size: 15px;
      
      text-align: center;
  `,

  InputDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      width: 68%;
      height: 40px;
  `,

  InputLabel: styled.p`
      font-size: 0.8vw;
      margin-bottom: 0.5vw;
  `,

  Input: styled.input`
      width: 15vw;
      height: 2vw;

      border: 1px solid #cccccc;
      border-radius: 0.3vw;
      

      font-size: 0.8vw;

      &:focus {
          border-color: #999999;
          outline: none;
      }
  `,

  WarningH6: styled.h6`
      font-size: 0.8vw;
      color: #EA6A6A;
      
      margin: 0 0;
  `,

}

export default PU;