// 프로젝트 관리 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Tab,
  Box,
} from "@material-ui/core";
import {TabList, TabPanel, TabContext} from '@material-ui/lab';
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import styled from 'styled-components';
import axios from 'axios';
import ProjectTable from "./components/ProjectTable";
import TotalProjectTable from "./components/TotalProjectTable";

// 전역 상태 관리
import { useRecoilState } from "recoil";
import { projectTableState } from "../../recoil/tableSetting";

function changeProjectData(array, index1, index2, index3){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    if(value1 == null){
      item[index1] = '';
    } else {
      item[index1] = value1.split('T')[0];
    }

    if(item[index3] == null){
      item[index3] = '0';
    } else {
      const share_id_length = (item[index3].split(',')).length
      item[index3] = share_id_length;
    }

    if(value2 == 'COMPLETED'){
      item[index2] = '완료';
    } else if(value2 == 'READY'){
      item[index2] = '준비';
    } else if (value2 == 'STOPPED'){
      item[index2] = '중단';
    } else {
      item[index2] = '';
    }
  })
  return array;
}

function changeTotalProjectData(array, index1, index2, index3, index4, index5, index6, index7){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    const value3 = item[index3];
    const value4 = item[index4];
    const value5 = item[index5];
    const value6 = item[index6];
    const value7 = item[index7];
    if(value1 == null){
      item[index1] = '';
    } else {
      item[index1] = value1.split('T')[0];
    }

    if(!value2 && !value3 && !value4 && !value5 && !value6 && !value7){
      item.data_process_status = '';
    } else {
      item.data_process_status = '완료';
    }
  })
  // console.log(array);
  return array;
}


export default function Project(){

  const [tableList, setTableList] = useState([]);
  const [totalTableList, setTotalTableList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row1 = ["NO","프로젝트 제목","소유자","생성일","진행상태","특허수","공유"];
  const row2 = ["NO","프로젝트 제목","소유자","생성일","진행상태"];
  const searchTargets = ["name", "data_process_status", "project_id"];


  //tab
  const [projectTable, setProjectTable] = useRecoilState(projectTableState); // Recoil 상태 사용

  const tabChange = (event, table) => {
    setProjectTable(table); // Tab 변경 시 memberTable 상태를 'total' 또는 'plan'으로 설정
  };

  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  //pagination api
  useEffect(() => {
    axios.get("/api/projects/list")
      .then((response) => {
        setTableList(changeProjectData([...response.data.data],"created_at", "data_process_status", "shared_member_ids").reverse())
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  useEffect(() => {
    axios.get("/api/projects/totalList")
      .then((response) => {
        setTotalTableList(changeTotalProjectData([...response.data.data], "created_time", "article_status", "nara_status", "news_status", "ntis_status", "pedia_status", "reports_status").reverse())
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  return(
    <div>
      <PageTitle title="프로젝트 관리" />
      <Box>
        <TabContext value={projectTable}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .PrivateTabIndicator-root-62.PrivateTabIndicator-colorSecondary-64.MuiTabs-indicator': { backgroundColor:'#6BB9FF' } }} style={{paddingLeft: '24px'}}>
            <TabList onChange={tabChange} aria-lable="프로젝트 관리">
              <Tab label="통합 프로젝트" value="total" />
              <Tab label="특허 프로젝트" value="patent" />
            </TabList>
          </Box>
          <Grid style={{paddingLeft: '24px', position: 'relative', top: '24px'}}>
            <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400'}} >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
              <SearchInput
                placeholder="Search"
                inputProps={{'aria-label': 'Search'}}
                onChange = {getValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </SearchPaper>
          </Grid>
          {/*통합프로젝트*/}
          <TabPanel value="total">
            <Grid item xs={12} >
              <Widget>
                <TotalProjectTable row={row2} filterKeyword={searchInput} totalData={totalTableList} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
          {/*특허 프로젝트*/}
          <TabPanel value="patent">
            <Grid item xs={12} >
              <Widget>
                <ProjectTable row={row1} filterKeyword={searchInput} totalData={tableList} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
};

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 24px;
    width: 20%;
`
