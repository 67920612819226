import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import classnames from "classnames";
// 비밀번호 암호화
import CryptoJS from 'crypto-js'; // AES 암호화를 위한 라이브러리
// styles
import useStyles from "./styles";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import axios from "axios";

function Login(props) {
  // const history = useHistory();
  let classes = useStyles();

  // global
  let userDispatch = useUserDispatch();

  // local
  let [isLoading, setIsLoading] = useState(false);
  let [, setError] = useState(false);
  let [activeTabId, setActiveTabId] = useState(0);
  let [loginValue, setLoginValue] = useState("");
  let [passwordValue, setPasswordValue] = useState("");

  let responseData = false;
  let [pwdIncorrect, setPwdIncorrect] = useState(false);

  const REACT_APP_CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
  const REACT_APP_CRYPTO_IV = process.env.REACT_APP_CRYPTO_IV;

  // 비밀번호 암호화 함수 (기존 코드 그대로)
  const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse(REACT_APP_CRYPTO_KEY.slice(0, 32)); // 256비트 키
    const iv = CryptoJS.enc.Utf8.parse(REACT_APP_CRYPTO_IV.slice(0, 16));   // 초기화 벡터 (IV)
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString(); // 암호화된 문자열 반환
  };

  const login = () => {
    const encryptPWD = encryptPassword(passwordValue);

    // 요청 보내기 전에 loading 상태 업데이트
    setIsLoading(true);

    axios.post("/api/userInform/gwLogin", {
      loginValue: loginValue,
      passwordValue: encryptPWD,
    })
      .then((response) => {
        const responseData = response.data.data;
        setPwdIncorrect(!responseData); // 로그인 실패 여부 상태 업데이트

        if (responseData) {
          const responseToken = response.data.token;

          // 로그인 성공 처리
          loginUser(responseData, responseToken, userDispatch, props.history, setIsLoading, setError, loginValue);

        } else {
          // 비밀번호가 틀린 경우에 처리
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "/api/userInform/gwLogin error");
        setIsLoading(false);  // 요청 실패 시에도 로딩 상태를 끝내야 함
      });
  };

  const keyPress = e => {
    if(e.key === 'Enter'){
      login()
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={require("../../images/techdna_logo.png")} alt="logo" />
        <Typography className={classes.logotypeText}>TechDNA GroupWare</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>TechDNA</Typography>
              <Typography variant="h1" className={classes.greeting}>그룹웨어</Typography>

              {pwdIncorrect && (
                <div>
                  <Typography color="secondary" className={classes.errorMessage}>
                    Something is wrong with your login or password :(
                  </Typography>
                </div>
              )}

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyPress={keyPress}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={loginValue.length === 0 || passwordValue.length === 0}
                    onClick={login}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          Copyright © 2024{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://techdna.kr"
            rel="noopener noreferrer"
            target="_blank"
          >
            TechDNA
          </a>{" "}
          | Powered by TECHDNA
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);