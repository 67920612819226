import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
} from "@material-ui/core";

const Q = {
  CloseDiv: styled.div`
      display: flex;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
  `,

  CloseButton: styled.div`
      position: relative;
      right: 0px;
      width: 24px;
      height: 24px;
      cursor: pointer;
  `,

  Header: styled.div`
      width: 100%;    
      display: flex;
      justify-content: center;
  `,

  Title: styled.span`
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      
      color: #4A4A4A;
  `,

  ConTent: styled.div`
      display: flex;
      justify-content: flex-start;
      align-items: center;
  `,

  ConTent2: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
  `,

  divTitle: styled.label`
      //margin-right: 10px;
      font-weight: bold;
  `,

  Paper: styled.div`
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      background-color: white;
      
      padding: 5px 10px;
  `,

}

export default Q;
