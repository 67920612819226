import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from 'react-router-dom';

// components
import Layout from "./Layout";
import GlobalStyle from "../styled-components/globalStyle";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import PopupSearchOrg from "./PopUp/popup_search_org"; // 소속기관 찾기 팝업

// context
import { useUserState } from "../context/UserContext";
import axios from "axios";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  axios.interceptors.request.use(
    function(config){
      const token = localStorage.getItem('id_token');
      if(token){
        config.headers['Authorization'] = `Bearer ${token}`
      }

      return config;
    }, function(error){
      return Promise.reject(error);
    }
  )

  axios.interceptors.response.use(
    function(config){
      return config;
    }, function(error){
      if(error.response.status === 401){
        localStorage.removeItem("id_token");
        // alert("토큰이 만료됐습니다.");
        window.location.href = "/login"
      }
    }
  )

  return (
    <BrowserRouter>
      {/*<GlobalStyle/>*/}
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/researchAll/researchDashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/researchAll/researchDashboard" />}
        />
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/app" component={Layout} />
        <Route path="/popup/searchOrg" component={PopupSearchOrg} />
        <Route component={Error} />
      </Switch>

    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
