import styled from 'styled-components';

const UTP = {
  TotalDiv: styled.div`
      width: 100%;
      height: 100%;
      
      position: fixed;
      top: 0px;
      left: 0px;
      
      z-index: 2000;

      background-color: rgba(0, 0, 0, 0.2);
      
      cursor: default;
  `,

  InnerTotalDiv: styled.div`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 70%;
      height: 70%;
  `,

  TableWrapper: styled.div`
    padding: 0px 20px;
    overflow-x: auto;
    width: 90%;
  `,

  StyledTable: styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  `,

  ColGroup: styled.colgroup`
    col:first-child {
        width: 5%;
    }
    col:nth-child(2) {
        width: 20%;
    }
    col:nth-child(3) {
        width: 25%;
    }
    col:nth-child(4) {
        width: 15%;
    }
    col:nth-child(5) {
        width: 20%;
    }
    col:nth-child(5) {
        width: 15%;
    }
  `,

  Thead: styled.thead`
    background-color: #f4f4f4;
  `,

  Th: styled.th`
    padding: 12px;
    text-align: center;
    font-weight: bold;
    background-color: #d3d3d3;
    border: 1px solid #ffffff;
  `,

  Tbody: styled.tbody`
    font-size: 14px;
  `,

  TbodyRow: styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }

    &:hover {
        background-color: #e9ecef;
    }
  `,

  Td: styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  `
}

export default UTP;