import React, { useEffect, useState } from "react";
import axios from "axios";

import PU from "../../../styled-components/popupBasicStyle";
import UTP from "../../../styled-components/userTablePopupStyle";
import styled from 'styled-components';

import TableRow from "../../../components/PopUp/table_row";

export default function UserTablePopup({ closePopup, head, orgType, memberStartDateValue, memberSelectedValue, startDate, endDate }) {

  const [info, setInfo] = useState(null);

  // 팝업 닫기
  const handleClosePopup = () => {
    closePopup();
  };

  // innerTotalDiv 클릭 시 팝업 닫히지 않도록
  const handleInnerClick = (e) => {
    e.stopPropagation(); // 부모 컴포넌트로 이벤트 전파를 막음
  };

  // 스크롤
  useEffect(() => {
    document.body.style.overflow = "hidden"; // 스크롤 막기

    return () => {
      document.body.style.overflow = "auto"; // 스크롤 복구
    };
  }, []);

  // 증감 회원 data
  const increaseMemData = async () => {

    const startDateTimestamp = new Date(memberStartDateValue).getTime();

    if (!memberStartDateValue) {
      return;  // 날짜가 유효하지 않으면 종료
    }
    try {
      const response = await axios.get(`/api/member/changes?term=${memberSelectedValue}&startDate=${startDateTimestamp}`);
      return response.data.data;
    } catch (error) {
      console.error('응답 못함', error);
    }
  };

  const tableDataFunc = async () => {
    let mainData = await increaseMemData();

    if (head == '플랜 회원') {
      mainData = mainData.filter(item => item.sub_seq !== 0);
    } else {
      const start_date = new Date(startDate);
      const end_date = new Date(endDate);

      // 시간을 00:00:00으로 설정하여 비교
      start_date.setHours(0, 0, 0, 0);
      end_date.setHours(23, 59, 59, 999);

      mainData = mainData.filter(item => {
        const subDate = new Date(item.sub_date); // ISO 8601 포맷 문자열을 Date 객체로 변환
        subDate.setHours(0, 0, 0, 0); // 시간 부분을 00:00:00으로 설정하여 날짜만 비교

        return subDate >= start_date && subDate <= end_date; // 날짜 범위 비교
      });
    }

    if (orgType !== '합계' && orgType !== '기타') {
      mainData = mainData.filter(item => item.org_type === orgType);
    }
    else if (orgType === '기타') {
      mainData = mainData.filter(item => item.org_type === '기타' || item.org_type === '없음(개인)' || item.org_type === null);
    }

    setInfo(mainData); // 필터링된 데이터 저장
  };

  useEffect(() => {
    tableDataFunc();
  }, [head, orgType, memberStartDateValue, memberSelectedValue, startDate, endDate]); // 의존성 배열 추가

  return (
    <UTP.TotalDiv onClick={handleClosePopup}>
      <UTP.InnerTotalDiv onClick={handleInnerClick}>
        <PU.CloseDiv>
          <PU.CloseButton onClick={handleClosePopup}>
            <PU.Line />
            <PU.Line />
          </PU.CloseButton>
        </PU.CloseDiv>

        {head === "플랜 회원" ?
          <PU.PopupTitle>신규 플랜 회원 정보</PU.PopupTitle>
        : 
          <PU.PopupTitle>신규 회원 정보</PU.PopupTitle>
        }

        <UTP.TableWrapper>
          <UTP.StyledTable>
            <UTP.ColGroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </UTP.ColGroup>
            <UTP.Thead>
              <tr>
                <UTP.Th>No</UTP.Th>
                <UTP.Th>소속기관명</UTP.Th>
                <UTP.Th>플랜명</UTP.Th>
                <UTP.Th>이름</UTP.Th>
                <UTP.Th>이메일</UTP.Th>
                <UTP.Th>비즈니스 이메일</UTP.Th>
              </tr>
            </UTP.Thead>
            <UTP.Tbody>
              {info && info.length > 0 ? (
                info.map((member, index) => (
                  <UTP.TbodyRow key={member.member_seq}>
                    <UTP.Td>{index + 1}</UTP.Td>
                    <UTP.Td>{member.org_name}</UTP.Td>
                    {member.note ? (
                      <UTP.Td>{member.note}</UTP.Td>
                    ) : (
                      <UTP.Td>가입된 플랜 없음</UTP.Td>
                    )}
                    <UTP.Td>{member.name}</UTP.Td>
                    <UTP.Td>{member.email}</UTP.Td>
                    {member.biz_email ? (
                      <UTP.Td>{member.biz_email}</UTP.Td>
                    ) : (
                      <UTP.Td>없음</UTP.Td>
                    )}
                  </UTP.TbodyRow>
                ))
              ) : (
                <UTP.TbodyRow>
                  <UTP.Td colSpan="6">loading...</UTP.Td>
                </UTP.TbodyRow>
              )}
            </UTP.Tbody>
          </UTP.StyledTable>
        </UTP.TableWrapper>
      </UTP.InnerTotalDiv>
    </UTP.TotalDiv>
  );
}
