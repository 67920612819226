import styled from 'styled-components';

const U = {
  TableContainer : styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin-bottom: 20px;
      
      font-size: 17px;
  `,

  StyledTable : styled.table`
      width: 90%;
      border-collapse: collapse;
      text-align: center;
      
      position: relative;
      top: 20px;
  
      th,
      td {
        border: 1px solid #ffffff;
        padding: 15px;
      }
    
      thead {
        background-color: #d3d3d3;
    
        th {
          font-weight: bold;
        }
      }
    
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    
      tbody tr:hover {
        background-color: #e9ecef;
      }
  `,

  VariationTD: styled.td`
      font-weight: bold;
      color: ${props =>
      props.value > 0 ? '#3085D6' : props.value === 0 ? '#A0A0A0' : '#FF6868'};
      background-color: ${props =>
              props.value > 0 ? '#D0E4FF' : props.value === 0 ? '' : '#FFD6D6'};
      cursor: ${props => (props.value > 0 ? 'pointer' : 'default')};
  `,

}

export default U;