import Widget from "../../../components/Widget";
import { Grid, Typography } from "@material-ui/core";
import EndGradeMemTable from "./EndGradeMemTable";

import React, { useEffect, useState } from "react";
import axios from "axios";

function ExpiringPlanMembers () {
  const [tableList, setTableList] = useState([]);
  const row = ["NO", "이름", "등급", "메일", "상품명", "소속", "플랜 시작일", "플랜 종료일"];
  const [selectedValue, setSelectedValue] = useState(1);

  function changeData(arr, index1) {
    arr.forEach((item) => {
      if (item[index1]) {
        item[index1] = item[index1].split('T')[0];
      }
    });
    return arr;
  }

  // 종료 예정 구독 회원 API 요청
  const fetchData = (term) => {
    axios.get(`/api/member/endGradeMember?term=${term}`)
      .then((response) => {
        const endMemberAPIData = changeData([...response.data.data], "end_date");
        setTableList(endMemberAPIData);
        console.log(endMemberAPIData);
      })
      .catch((error) => {
        console.error("API 요청 실패:", error);
      });
  };

  // 선택 값 변경 시 API 호출
  useEffect(() => {
    fetchData(selectedValue);
  }, [selectedValue]);

  // 매출 선택값 변경 핸들러
  const expiringValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Widget>
          <Grid style={{ display: "flex" }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              플랜 종료 예정 회원
            </Typography>
            <Grid style={{ marginLeft: 20 }}>
              <select
                className="input_style"
                onChange={expiringValueChange}
                value={selectedValue}
              >
                <option value={1}>1 Week</option>
                <option value={2}>2 Week</option>
                <option value={3}>Month</option>
              </select>
            </Grid>
            <Typography variant="h6" style={{ color: "#ff3873" }}>
              &nbsp;&nbsp;총 {tableList?.length || 0}명
            </Typography>
          </Grid>
          <Grid container spacing={1} style={{ display: "contents" }}>
            <EndGradeMemTable row={row} totalData={tableList} />
          </Grid>
        </Widget>
      </Grid>
    </>
  );
}

export default ExpiringPlanMembers;
