import { atom, selector, useRecoilCallback } from "recoil";

const planDataState = atom({
  key: "planDataState",
  default: {
    dataChange: false,
    rowDataChange: false,
    paidSeqState: "",
  },
});

export const dataChangeState = selector({
  key: "dataChangeState",
  get: ({ get }) => get(planDataState).dataChange,
  set: ({ get, set }, dataChange) => set(planDataState, { ...get(planDataState), dataChange }),
});

export const rowDataChangeState = selector({
  key: "rowDataChangeState",
  get: ({ get }) => get(planDataState).rowDataChange,
  set: ({ get, set }, rowDataChange) => set(planDataState, { ...get(planDataState), rowDataChange }),
});

export const paidSeqState = selector({
  key: "paidSeqState",
  get: ({ get }) => get(planDataState).paidSeqState,
  set: ({ get, set }, paidSeqState) => set(planDataState, { ...get(planDataState), paidSeqState }),
});

export const ResetPlanDataState = () => {
  const resetStateCallback = useRecoilCallback(({ reset }) => () => {
    reset(planDataState);
  });

  return resetStateCallback();
};
