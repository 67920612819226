import React, { useEffect, useState } from "react";
import axios from "axios";

// 전역 상태 관리
import { useRecoilState } from "recoil";
import { dataChangeState, rowDataChangeState, paidSeqState } from "../../recoil/changePlanData";

// css
import PU from "../../styled-components/popupBasicStyle"
import {
  Button
} from "@material-ui/core";


function SubMember({ closePopup, perItem, perInfo, btnStatus }) {
  const [inputEmail, setInputEmail] = useState("");
  const [warning, setWarning] = useState("");

  // 작업 완료 시
  const [complete, setComplete] = useState(false);
  const [completeMessage, setCompleteMessage] = useState("");

  // 이메일 정규식
  const emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

  // TotalDiv 클릭 시 팝업 닫기
  const handleClosePopup = () => {
    closePopup();
  };

  // innerTotalDiv 클릭 시 팝업 닫히지 않도록
  const handleInnerClick = (e) => {
    e.stopPropagation(); // 부모 컴포넌트로 이벤트 전파를 막음
  };

  // reload를 위한 전역 상태 변수
  const [, setDataChange] = useRecoilState(dataChangeState);
  const [, setRowDataChange] = useRecoilState(rowDataChangeState);
  const [, setPaidSeqSelect] = useRecoilState(paidSeqState);

  const deletePlanMember = async () => {
    const memberSeq = perInfo.member_seq;
    console.log("perInfo.member_seq", perInfo.member_seq);

    try {
      const response = await axios.put('/api/plan/deleteSubscription', null, {
        params: { member_seq: memberSeq },
      });

      if(response.status === 200){
        setCompleteMessage("제거 되었습니다.");
        setComplete(true);

        setPaidSeqSelect(perItem.paid_seq);
        setRowDataChange(true);
      }
    } catch (error) {
      setCompleteMessage("제거에 실패했습니다.");
      setComplete(true);
    }
  };

  const registerSubscription = async (memberSeq) => {

    console.log("item.grant_code", perItem.grant_code);
    console.log("memberSeq", memberSeq);

    try {
      // API 호출
      const response = await axios.get('/api/plan/registerSubscription', {
        params: {
          coupon_code: perItem.grant_code,
          member_seq: memberSeq,
        },
      });

      const message = response.data.data.message;

      if (message === "쿠폰 번호가 일치하지 않거나, 사용이 만료되었습니다.") {
        setWarning(message);
      }
      else {
        setCompleteMessage(message);
        console.log(response);
        setComplete(true);

        setPaidSeqSelect(perItem.paid_seq);
        setRowDataChange(true);
      }
    } catch (error) {
      setCompleteMessage("추가에 실패했습니다.");
      setComplete(true);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // 스크롤 막기
    return () => {
      document.body.style.overflow = "auto"; // 스크롤 복구
    };
  }, []);


  // 이메일 입력 상태 변경 함수
  const handleInputChange = (e) => {
    setInputEmail(e.target.value);
  };

  const searchEmail = async () => {

    const email = inputEmail;

    if (!emailRegex.test(email)) {
      setWarning("이메일 형식이 올바르지 않습니다.");
      return
    }

    try {
      // API 호출
      const response = await axios.get('/api/plan/getMemberSeq', {
        params: { email }, // 쿼리 파라미터 전달
      });

      const status = response.status;
      const memberSeq = response.data.data;

      console.log(response);
      if (status === 200) {
        registerSubscription(memberSeq);
      }
    }
    catch (error) {
      setWarning("회원 번호 조회에 실패하였습니다.");
    }
  };

  return (
    <PU.TotalDiv onClick={handleClosePopup}>
      <PU.InnerTotalDiv onClick={handleInnerClick}>
        <PU.CloseDiv>
          <PU.CloseButton onClick={handleClosePopup}>
            <PU.Line/>
            <PU.Line/>
          </PU.CloseButton>
        </PU.CloseDiv>

        {(btnStatus === "제거" && !complete) &&
          <>
            <PU.TitleH1>플랜 제거</PU.TitleH1>
            <PU.DescriptionP>
              <PU.BoldSpan>{perInfo.name} 님</PU.BoldSpan>의<br/>
              <PU.BoldSpan>{perItem.note} 플랜</PU.BoldSpan>을 제거합니다.
            </PU.DescriptionP>
            <PU.BtnDiv>
              <Button style={{backgroundColor:"#EA6A6A", color: "white", fontWeight: 'medium', boxShadow: 'none'}} onClick={handleClosePopup}>아니요</Button>
              <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} onClick={deletePlanMember}>예</Button>
            </PU.BtnDiv>
          </>
        }
        {(btnStatus === "추가" && !complete) &&
          <>
            <PU.TitleH1>플랜 설정</PU.TitleH1>
            <PU.ExplainP>플랜을 적용 할 E-mail을 입력해주세요.</PU.ExplainP>
            <PU.OtherP>
              플랜은 ResearchALL 의 모든 기능을 정해진 기간에 따라 사용
              가능합니다.
            </PU.OtherP>

            <PU.InputLabel>적용할 회원 이메일</PU.InputLabel>
            <PU.InputDiv>
              <PU.Input
                type="text"
                placeholder="이메일 입력"
                value={inputEmail}
                onChange={handleInputChange}
              />
              <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}}  onClick={searchEmail}>추가</Button>
            </PU.InputDiv>
            <PU.WarningH6>{warning}</PU.WarningH6>
          </>
        }
        {complete &&
          <>
            <PU.TitleH1>{completeMessage}</PU.TitleH1>
          </>
        }

      </PU.InnerTotalDiv>
    </PU.TotalDiv>
  );
}

export default SubMember;