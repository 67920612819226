import React, { useEffect, useState } from "react";
import PU from "../../styled-components/popupBasicStyle";

function PopupBasic({closePopup, turnBack, title, context}) {

  const handleClosePopup = () => {
    turnBack();
    closePopup();
  };

  // innerTotalDiv 클릭 시 팝업 닫히지 않도록
  const handleInnerClick = (e) => {
    e.stopPropagation(); // 부모 컴포넌트로 이벤트 전파를 막음
  };

  // 스크롤
  useEffect(() => {
    document.body.style.overflow = "hidden"; // 스크롤 막기
    return () => {
      document.body.style.overflow = "auto"; // 스크롤 복구
    };
  }, []);

  return (
    <PU.TotalDiv onClick={handleClosePopup}>
      <PU.InnerTotalDiv onClick={handleInnerClick}>
        <PU.CloseDiv>
          <PU.CloseButton onClick={handleClosePopup}>
            <PU.Line />
            <PU.Line />
          </PU.CloseButton>
        </PU.CloseDiv>
        {/*상단까지 기본 컴포넌트*/}

        <PU.TitleH1>{title}</PU.TitleH1>

        {
          context &&
          <PU.DescriptionP>{context}</PU.DescriptionP>
        }

        {/*하단부터 기본 컴포넌트*/}
      </PU.InnerTotalDiv>
    </PU.TotalDiv>
  );
}

export default PopupBasic;