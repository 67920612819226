import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter
} from "@material-ui/core";
import DirectDetailPopUp from "../../MemberCenter/components/DirectDetailPopUp";

export default function DirectTable(props){
  const row = props.row;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage=5;
  const indexOfLastPost = page*postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const handlePageChange = (page) => {
    setPage(page);
  };
  const [seq, setSeq] = useState();
  const [detailOpen, setDetailOpen] = useState();

  const detailPopUpOpen = (item) => {
    setDetailOpen(true);
    setSeq(item.bbs_direct_seq);
  }
  const detailPopUpClose = () => {
    setDetailOpen(false);
    // setSeq(null);
  }

  useEffect(()=>{
    setCurrentPost(totalData.slice(indexOfFirstPost, indexOfLastPost))
    if(totalData.length === 0){
      setDataCnt(1)
    } else{ setDataCnt(totalData.length) }

    console.log("props :", props);
  },[totalData, page]);

  return(
    <div>
      <TableContainer>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              {row && row.map(headerItem => (
                <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost && currentPost.map(item => (
              <TableRow key={item.num}>
                <TableCell style={{ textAlign: 'center'}}>{item.title}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.member_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.org_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.request_date}</TableCell>
                <TableCell style={{cursor:"pointer", textAlign: 'center'}} onClick={() => detailPopUpOpen(item)}>보기</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RealTableFooter>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
      <DirectDetailPopUp open={detailOpen} close={detailPopUpClose} seq={seq} />
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`