import React, { useState } from "react";
import S from "../../styled-components/searchOrgPopupStyle"

function TableRow({ org, onRowClick }) {

  // css (임시로 적용)
  const handleClick = () => {
    onRowClick(org.org_seq, org.org_name); // 클릭 시 부모 컴포넌트로 org_seq 전달
    console.log("org", org);
  };

  return (
    <S.TbodyRow
      key={org.org_seq}
      onClick={handleClick}
    >
      <S.Td>{org.no}</S.Td>
      <S.Td>{org.org_name}</S.Td>
      <S.Td>{org.org_biznum}</S.Td>
    </S.TbodyRow>
  );
}

export default TableRow;