import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';

import P from "../../styled-components/planStyle"
import PopupBasic from "../../components/PopUp/popup_basic";

function CreatePlan() {

  const [note, setNote] = useState("");
  const [warningNote, setWarningNote] = useState("");

  const [price, setPrice] = useState("");
  const [warningPrice, setWarningPrice] = useState("");

  const [period, setPeriod] = useState("");
  const [warningPeriod, setWarningPeriod] = useState("");

  const [memberCount, setMemberCount] = useState(0);
  const [warningMemberCount, setWarningMemberCount] = useState("");

  const [planCategory, setPlanCategory] = useState("MONTH");

  // 플랜 생성 결과
  const [createSuccess, setCreateSuccess] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const [turnBack, setTurnBack] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");

  // 이전 페이지로 이동
  const history = useHistory();

  const handleInputChange = (e, inputCase) => {
    switch (inputCase) {
      case "상품명":
        setNote(e.target.value);
        break;

      case "가격":
        setPrice(e.target.value);
        break;

      case "기간":
        setPeriod(e.target.value);
        break;

      case "회원":
        setMemberCount(e.target.value);
        break;
        
      case "등급":
        console.log("등급 이벤트 :", e);
        setPlanCategory(e.target.value);
        break;
    }
  };

  // 플랜 생성 버튼 클릭 시 유효성 검사
  const handleButtonClick = () => {
      if (note === "") {
        setWarningNote("상품명을 입력해주세요.");
        setWarningPrice("");
        setWarningPeriod("");
        setWarningMemberCount("");
        return
      }
      if (!/^\d+$/.test(price)) {
        setWarningNote("");
        setWarningPrice("최대 11자리 숫자만 입력해주세요");
        setWarningPeriod("");
        setWarningMemberCount("");
      }
      else if (!/^\d+$/.test(period)) {
        setWarningNote("");
        setWarningPrice("");
        setWarningPeriod("기간을 입력해주세요.");
        setWarningMemberCount("");
        return
      }
      else if (memberCount === 0) {
        setWarningNote("");
        setWarningPrice("");
        setWarningPeriod("");
        setWarningMemberCount("이용 회원 수를 입력해주세요.");
        return
      }
      else {
        setWarningNote("");
        setWarningPrice("");
        setWarningPeriod("");
        setWarningMemberCount("");

        buySubscription();
      }
  };

  const buySubscription = async () => {

    console.log("planCategory :", planCategory);

    const memberSeq = localStorage.getItem('memberSeq');

    try {
      const response = await axios.put(
        `/api/plan/buySubscription`,
        { // 요청 본문에 데이터 포함
          note: note,
          price: Number(price),
          period: period,
          usable_member_cnt: Number(memberCount),
          kind: planCategory,
          member_seq: Number(memberSeq),
        }
      );

      const statusCode = response.data.code;

      if (statusCode === 0) {
        setPopupTitle("플랜 생성이 완료됐습니다.");
        setCreateSuccess(true); // 기능 구현을 위해 페이지 이동 막음. (추후 주석 해제)
        setOpenPopup(true);
      }
      else {
        setPopupTitle("플랜 생성에 실패했습니다.");
        setOpenPopup(true);
      }

    } catch (error) {
      setPopupTitle("플랜 생성에 실패했습니다.");
      setOpenPopup(true);
    }
  };

  useEffect(() => {
    if (turnBack) {
      setTurnBack(false);

      if(createSuccess) {
        // history.goBack();
      }
    }
  }, [turnBack]);

  return (
    <>
      <P.TotalDiv>
        <P.InnerTotalDiv>
          <P.TitleH4 style={{marginBottom: "50px"}}>Plan 생성</P.TitleH4>

          <P.BasicDiv>
            <P.BasicLabel>상품명</P.BasicLabel>
            <P.Input
              type="text"
              placeholder="상품명을 입력해주세요"
              value={note}
              onChange={(e) => {handleInputChange(e, "상품명")}}
            />
            <P.CheckResultP>{warningNote}</P.CheckResultP>
          </P.BasicDiv>

          <P.BasicDiv>
            <P.BasicLabel>가격</P.BasicLabel>
            <P.Input
              type="text"
              placeholder="최대 11자리 숫자만 입력해주세요"
              maxLength={11}
              onChange={(e) => {handleInputChange(e, "가격")}}
            />
            <P.CheckResultP>{warningPrice}</P.CheckResultP>
          </P.BasicDiv>

          <P.BasicDiv>
            <P.BasicLabel>기간(개월)</P.BasicLabel>
            <P.Input
              type="text"
              placeholder="최대 2자리 숫자만 입력해주세요"
              maxLength={2}
              onChange={(e) => {handleInputChange(e, "기간")}}
            />
            <P.CheckResultP>{warningPeriod}</P.CheckResultP>
          </P.BasicDiv>

          <P.BasicDiv>
            <P.BasicLabel>이용 회원 수</P.BasicLabel>
            <P.Input
              type="number"
              placeholder="이용할 회원의 수를 입력해주세요"
              onChange={(e) => {handleInputChange(e, "회원")}}
            />
            <P.CheckResultP>{warningMemberCount}</P.CheckResultP>
          </P.BasicDiv>

          <P.BasicDiv>
            <P.BasicLabel>등급</P.BasicLabel>
            <P.ClassSelect
              onChange={(e) => {handleInputChange(e, "등급")}}
            >
              <option value={"MONTH"}>MONTH</option>
              <option value={"YEAR"}>YEAR</option>
            </P.ClassSelect>
          </P.BasicDiv>

          <P.SubmitBtn
            onClick={handleButtonClick}
          >
            플랜 생성</P.SubmitBtn>
        </P.InnerTotalDiv>
      </P.TotalDiv>
      {openPopup?
        <PopupBasic
          closePopup={() => setOpenPopup(false)}
          turnBack={() => setTurnBack(true)}
          title = {popupTitle}
        />
        :
        null
      }
    </>
  );
}

export default CreatePlan;