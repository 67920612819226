import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter
} from "@material-ui/core";

export default function EndGradeMemTable(props){
  const row = ['이름', '등급', '메일', '소속', '플랜 시작일', '플랜 종료일'];
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage = 5;
  const indexOfLastPost = page*postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(()=>{
    if(totalData && totalData.length > 0){
      setCurrentPost(totalData.slice(indexOfFirstPost, indexOfLastPost))
      setDataCnt(totalData.length)
    } else{
      setDataCnt(1)
    }
  },[totalData, page]);

  return(
    <div>
      <TableContainer>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              {row && row.map(headerItem => (
                <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost && currentPost.map(item => (
              <TableRow key={item.num}>
                <TableCell style={{ textAlign: 'center'}}>{item.name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.grade_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.email}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.org_type}<br />{item.org_name}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.start_date}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.end_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RealTableFooter>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`