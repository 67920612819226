import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
} from "@material-ui/core";
import { SortButton } from "../../../styled-components/planStyle";

// component
import CouponListTableMore from "./CouponListTableMore";
import EditContextPopup from "./editContextPopup";

// 전역 상태 관리
import { useRecoilState } from "recoil";
import { dataChangeState, rowDataChangeState, paidSeqState  } from "../../../recoil/changePlanData";

// css
import styled from "styled-components";
import P from "../../../styled-components/planStyle"
import "../../../components/Style/Table.css";
import sortAsc from "../../../images/ico_filter_up.svg"
import sortDesc from "../../../images/ico_filter_down.svg"

export default function CouponListTable({filterKeyword})  {

  const searchTargets = ["grant_code", "note"];

  // 전체 데이터
  const [totalData, setTotalData] = useState([]);

  // pagination
  const [dataCnt, setDataCnt] = useState(0);
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage = 10;
  const indexOfLastPost = page * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  // edit popup
  const [popupOpen, setPopupOpen] = useState(false);
  const [paidSeq, setPaidSeq] = useState(0);
  const [paidNote, setPaidNote] = useState("");
  const [editPart, setEditPart] = useState("");
  const [prevData, setPrevData] = useState(null);

  // reload를 위한 전역 상태 변수
  const [rowdataChange, setRowDataChange] = useRecoilState(rowDataChangeState);
  const [paidSeqSelect, setPaidSeqSelect] = useRecoilState(paidSeqState);
  const [dataChange, setDataChange] = useRecoilState(dataChangeState);

  // filter
  const [memberSelectedValue, setMemberSelectedValue] = useState(1);
  const [filterChange, setFilterChange] = useState(false);

  // 자식 행 표시
  const [expandedRows, setExpandedRows] = useState({});

  // 쿠폰 목록 API 호출 함수
  const fetchCouponList = async (selectedValue) => {
    try {
      const response = await axios.get('/api/member/accessCouponList', {
        params: { value: selectedValue } // 쿼리 파라미터로 전달
      });
      setTotalData([...response.data.data]); // 테이블 리스트 업데이트
      if (filterChange) {
        setExpandedRows({}); // 필터 변경 시에만 초기화
        console.log('fetchCouponList 함수에서 setExpandedRows 초기화 됨.')
        setFilterChange(false);
      }
      setDataChange(false);
    } catch (error) {
      console.error("쿠폰 목록 가져오기 실패:", error.message);
    }
  };

  // memberSelectedValue 변경 시마다 쿠폰 목록 갱신
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false; // 첫 로딩 후 false로 변경
      fetchCouponList(memberSelectedValue);
      return; // 필터 변경 플래그 설정하지 않음
    }

    setFilterChange(true);
    fetchCouponList(memberSelectedValue);
  }, [memberSelectedValue]);


  // 데이터 변경 시마다 쿠폰 목록 갱신
  useEffect(() => {
    if (dataChange === true) {
      fetchCouponList(memberSelectedValue);
    }
  }, [dataChange]);

  // 정렬 기준 변화
  const memberValueChange = (filter) => {
    let newValue = memberSelectedValue;

    switch (filter) {
      case "구독 결제 번호":
        newValue = memberSelectedValue === 1 ? 2 : 1;
        break;

      case "가격":
        newValue = memberSelectedValue === 3 ? 4 : 3;
        break;

      case "시작일":
        newValue = memberSelectedValue === 5 ? 6 : 5;
        break;

      case "종료일":
        // 7 : 종료일 임박순
        // 8 : 종료일 과거순
        // 9 : 종료일 미래순
        newValue = memberSelectedValue === 7 ? 9 : 7;
        break;

      default:
        console.warn("⚠️ 잘못된 필터값:", filter);
        return;
    }

    setMemberSelectedValue(newValue);
  };

  //search
  const searchItem = (filterKeyword) => {
    if (filterKeyword && filterKeyword !== '') {
      const filteredData = totalData.filter((item) => {
        return searchTargets.some((target) => {
          if (item[target] && typeof item[target] === 'string') {
            return item[target].toLowerCase().includes(filterKeyword.toLowerCase());
          }
        })
      });
      // pagination filter data slice
      setCurrentPost(filteredData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(filteredData.length);
    } else {
      // pagination total data slice
      // setCurrentPost(totalData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setCurrentPost((totalData || []).slice(page*postPerPage-postPerPage, page*postPerPage));

      setDataCnt(totalData.length);
    }
  }

  useEffect(() => {
    searchItem(filterKeyword)
  }, [totalData, page, filterKeyword])

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (totalData && totalData.length > 0) {
      setCurrentPost(totalData.slice(indexOfFirstPost, indexOfLastPost));
      setDataCnt(totalData.length);
    } else {
      setDataCnt(1);
    }
  }, [totalData, page]);

  const getMoreInfo = async (paid_seq) => {
    try {
      const response = await axios.get(`/api/admin/subMember?paid_seq=${paid_seq}`);
      console.log(response);
      return response.data.data;
    } catch (error) {
      console.error("getMoreInfo error:", error);
      return [];
    }
  };

  const handleEditIconClick = (event, item, clickPart, data) => {
    // 이벤트 전파 중단 (Row 클릭 이벤트와 분리)
    event.stopPropagation();

    setPaidSeq(item.paid_seq);
    setPaidNote(item.note);
    setEditPart(clickPart);
    setPrevData(data);

    setPopupOpen(true);
  };

  const handleRowClick = async (rowPaidSeq) => {
    if (expandedRows[rowPaidSeq]) {
      // 이미 열려 있다면 닫기
      setExpandedRows((prev) => {
        const updatedRows = { ...prev };
        delete updatedRows[rowPaidSeq];
        return updatedRows;
      });
    } else {
      // 새 데이터를 가져와서 열기
      const data = await getMoreInfo(rowPaidSeq);
      setExpandedRows((prev) => ({ ...prev, [rowPaidSeq]: data }));
    }
  };

  useEffect(() => {
    if (!rowdataChange) return; // 변경이 없으면 실행 안 함

    const fetchData = async () => {
      const data = await getMoreInfo(paidSeqSelect);

      setExpandedRows((prev) => ({
        ...prev,
        [paidSeqSelect]: data, // 기존 값 유지 + 새로운 데이터 추가
      }));

      setRowDataChange(false);
      setPaidSeqSelect(false);
    };

    fetchData();
  }, [rowdataChange]);

  return (
    <>
      <TableContainer>
        <Table
          style={{ tableLayout: "fixed", width: "100%", cursor: "pointer" }}
        >
          <colgroup>
            <col style={{ width: "10%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <P.TableCellHead>
                <P.TableCellHeadDiv>
                  구독 결제 번호
                  {memberSelectedValue === 1 && <P.sortBtn src={sortDesc || ""} alt="sortDesc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("구독 결제 번호");}}/>}
                  {memberSelectedValue === 2 && <P.sortBtn src={sortAsc || ""} alt="sortAsc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("구독 결제 번호");}}/>}
                  {(memberSelectedValue !== 1 && memberSelectedValue !== 2) && (<P.sortBtn src={sortDesc || ""} alt="sortDesc"
                                                                                           onClick={() => {memberValueChange("구독 결제 번호");}}/>)}
                </P.TableCellHeadDiv>
              </P.TableCellHead>

              <P.TableCellHead>상품명</P.TableCellHead>

              <P.TableCellHead>사용 인원</P.TableCellHead>

              <P.TableCellHead>
                <P.TableCellHeadDiv>
                  가격
                  {memberSelectedValue === 3 && <P.sortBtn src={sortDesc || ""} alt="sortDesc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("가격");}}/>}
                  {memberSelectedValue === 4 && <P.sortBtn src={sortAsc || ""} alt="sortAsc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("가격");}}/>}
                  {(memberSelectedValue !== 3 && memberSelectedValue !== 4) && (<P.sortBtn src={sortDesc || ""} alt="sortDesc"
                                                                                           onClick={() => {memberValueChange("가격");}}/>)}
                </P.TableCellHeadDiv>
              </P.TableCellHead>

              <P.TableCellHead>
                <P.TableCellHeadDiv>
                  시작일
                  {memberSelectedValue === 5 && <P.sortBtn src={sortDesc || ""} alt="sortDesc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("시작일");}}/>}
                  {memberSelectedValue === 6 && <P.sortBtn src={sortAsc || ""} alt="sortAsc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("시작일");}}/>}
                  {(memberSelectedValue !== 5 && memberSelectedValue !== 6) && (<P.sortBtn src={sortDesc || ""} alt="sortDesc"
                                                                                           onClick={() => {memberValueChange("시작일");}}/>)}
                </P.TableCellHeadDiv>
              </P.TableCellHead>

              <P.TableCellHead>
                <P.TableCellHeadDiv>
                  종료일
                  {memberSelectedValue === 7 && <P.sortBtn src={sortAsc || ""} alt="sortAsc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("종료일");}}/>}
                  {memberSelectedValue === 9 && <P.sortBtn src={sortDesc || ""} alt="sortDesc" style={{opacity : 1}}
                                                           onClick={() => {memberValueChange("종료일");}}/>}
                  {(memberSelectedValue !== 7 && memberSelectedValue !== 9) && (<P.sortBtn src={sortAsc || ""} alt="sortAsc"
                                                                                           onClick={() => {memberValueChange("종료일");}}/>)}
                </P.TableCellHeadDiv>
              </P.TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost &&
              currentPost.map((item) => {
                // isEnd 변수를 item 내에서 계산
                const isValidDate = (dateStr) =>
                  dateStr && !isNaN(new Date(dateStr).getTime());
                const isEnd = isValidDate(item.end_date)
                  ? new Date(item.end_date.split("T")[0]) < new Date()
                  : false;

                // JSX 반환
                return (
                  <React.Fragment key={item.num}>
                    <TableRow
                      onClick={() => handleRowClick(item.paid_seq)}
                      style={{ cursor: isEnd ? "default" : "pointer" }}
                    >
                      <P.TableCellEdit>{item.paid_seq}</P.TableCellEdit>
                      <P.TableCellEdit>
                        <P.EditableDiv>
                          <p>{item.note}</p>
                          {!isEnd && (
                            <P.FaRegEditIcon
                              onClick={(e) =>
                                handleEditIconClick(
                                  e,
                                  item,
                                  "상품명",
                                  item.note,
                                )
                              }
                            />
                          )}
                        </P.EditableDiv>
                      </P.TableCellEdit>
                      <P.TableCellEdit>
                        {item.end_date && isEnd ? (
                          <P.EndP>종료</P.EndP> // 종료된 경우
                        ) : (
                          <>
                            {item.use_member_cnt}/{item.usable_member_cnt}{" "}
                            {/* 사용 가능 */}
                          </>
                        )}
                      </P.TableCellEdit>
                      <P.TableCellEdit>
                        <P.EditableDiv
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingRight: "60px",
                          }}
                        >
                          <p>{item.paid_price.toLocaleString()}</p>
                          {!isEnd && (
                            <P.FaRegEditIcon
                              onClick={(e) =>
                                handleEditIconClick(
                                  e,
                                  item,
                                  "가격",
                                  item.paid_price,
                                )
                              }
                            />
                          )}
                        </P.EditableDiv>
                      </P.TableCellEdit>
                      <P.TableCellEdit>
                        {item.issue_date
                          ? item.issue_date.slice(0, 10)
                          : "없음"}
                      </P.TableCellEdit>
                      <P.TableCellEdit>
                        <P.EditableDiv>
                          <p>{item.end_date?.slice(0, 10) ?? "없음"}</p>
                          {!isEnd && (
                            <P.FaRegEditIcon
                              onClick={(e) =>
                                handleEditIconClick(
                                  e,
                                  item,
                                  "종료일",
                                  item.end_date,
                                )
                              }
                            />
                          )}
                        </P.EditableDiv>
                      </P.TableCellEdit>
                    </TableRow>

                    {/*자세히 보기*/}
                    <CouponListTableMore
                      item={item}
                      expandedData={expandedRows[item.paid_seq]}
                      isEnd={isEnd}
                    />
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>

        <RealTableFooter style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>

      {/* 수정 팝업*/}
      {popupOpen ? (
        <EditContextPopup
          closePopup={() => setPopupOpen(false)}
          paidSeq={paidSeq}
          paidNote={paidNote}
          editPart={editPart}
          prevData={prevData}
        />
      ) : null}
    </>
  );
}

const RealTableFooter = styled(TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`;
