import React, { useState, useEffect } from "react";
import axios from "axios";

import Widget from "../../../components/Widget";
import { Grid, Typography } from "@material-ui/core";
import DirectTable from "./DirectTable";

function WeeklyInquiry() {
  const row = ["제목", "이름", "소속","문의일", "상세보기"];
  const [directList, setDirectList] = useState([]);

  function changeData(arr, index1){
    arr.forEach((item) => {
      const value1 = item[index1];
      item[index1] = value1.split('T')[0];
    })
    return arr;
  }

  // 1:1 문의 api
  useEffect(() => {
    axios.get("/api/memberCenter/weekDirectList")
      .then((response) => {
        // console.log(response.data.data)
        const directAPIData = changeData([...response.data.data],"request_date");
        setDirectList(directAPIData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  return(
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Widget>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            이번주 1:1 문의
          </Typography>
          <Grid container spacing={1} style={{ display: "contents" }}>
            <DirectTable row={row} totalData={directList} />
          </Grid>
        </Widget>
      </Grid>
    </>
  )
}

export default WeeklyInquiry;