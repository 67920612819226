import React, { useEffect, useState } from "react";
import axios from "axios";
import D from "../../../styled-components/dashBoardStyle";
import { useHistory } from "react-router-dom";
import Widget from "../../../components/Widget";
import dayjs from 'dayjs';

// 전역 상태 관리
import { useRecoilState } from "recoil";
import { memberTableState, projectTableState, noticeTableState } from "../../../recoil/tableSetting";

function ServiceStatusTable(){
  const today = new Date();
  const todayFormat = dayjs(today).endOf('day'); // 시간을 23:59:59로 설정

  // 오늘 날짜
  const [memberStartDateValue,setMemberStartDateValue] = useState(todayFormat);
  
  // 전체 회원 수
  const [memberList, setMemberList] = useState();
  const [filteredMember, setFilteredMember] = useState();
  // 플랜 회원 수
  const [topPlanMemCnt, setTopPlanMemCnt] = useState(null);
  
  // 통합 프로젝트 수
  const [totalProject, setTotalProject] = useState([]);
  const [lastWeekTotalProject, setLastWeekTotalProject] = useState([]);
  
  // 특허 프로젝트 수
  const [lastWeekAppliProject, setLastWeekAppliProject] = useState([]);
  const [appliProject, setAppliProject] = useState([]);

  // 1:1 문의 수
  const [directList, setDirectList] = useState([]);

  // 승인 대기 정비 수
  const [waitAppliData, setWaitAppliData] = useState([]);

  const history = useHistory();
  // Recoil 상태 사용
  const [, setMemberTable] = useRecoilState(memberTableState);
  const [, setProjectTable] = useRecoilState(projectTableState);
  const [, setNoticeTable] = useRecoilState(noticeTableState);

  const navigateToPage = (num) => {

    let linkTo;

    switch (num) {
      case 1:
        setMemberTable("total");
        linkTo = "/app/researchAll/member";
        break;
      case 2:
        setMemberTable("plan");
        linkTo = "/app/researchAll/member";
        break;
      case 3:
        setProjectTable("total");
        linkTo = "/app/researchAll/project";
        break;
      case 4:
        setProjectTable("patent");
        linkTo = "/app/researchAll/project";
        break;
      case 5:
        setNoticeTable("question");
        linkTo = "/app/researchAll/memberCenter";
        break;
      case 6:
        linkTo = "/app/researchAll/applicant";
        break;
      default:
        break;
    }

    history.push(linkTo);
  };

  // 전체회원 org_type 데이터 가공
  function mergeData(arr){
    let arr4 = [];
    arr.forEach((item) => {
      const arr1 = item[0];
      const arr2 = item[1];
      const arr3 = item[2];
      for(let i=0; i<7; i++){
        let ret = {
          date: arr1[i],
          totalMember: arr2[i],
          subscribeMember: arr3[i]
        }
        arr4.push(ret);
      }
    })
    return arr4;
  }

  // 플랜 회원 수
  useEffect(() => {
    if(memberStartDateValue){
      axios.get("/api/member/memberGraph?term=" + 2 + "&startDate=" + memberStartDateValue)
        .then((response) => {
          const memberData = mergeData([response.data.data])

          const planMemData = [];
          planMemData.push(
            memberData[5]?.subscribeMember.전체합, // 어제 플랜 회원 수
            memberData[6]?.subscribeMember.전체합, // 오늘 플랜 회원 수
            memberData[6]?.subscribeMember.전체합 - memberData[5]?.subscribeMember.전체합
          ); // 플랜 회원 수 증감
          setTopPlanMemCnt(planMemData);

          setMemberList(
            memberData[6]?.totalMember.전체합
          );

          const value = memberData[6]?.totalMember.전체합 - memberData[5]?.totalMember.전체합;
          setFilteredMember(value);
        })
    }
  }, []);

  // 통합 프로젝트
  useEffect(() => {
    axios
      .get("/api/projects/totalList")
      .then((response) => {
        const projectAPI = response.data.data;

        // todayFormat을 이용하여 7일 전 날짜 계산
        const sevenDaysAgo = dayjs(today).subtract(7, 'day').endOf('day');

        const filteredData = projectAPI.filter(
          (item) => dayjs(item.created_time).isBefore(sevenDaysAgo), // created_time이 7일 이전인지 확인
        );

        // 상태 업데이트
        setLastWeekTotalProject(filteredData); // 7일 이전 프로젝트만 저장
        setTotalProject(projectAPI); // 전체 프로젝트 저장
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  
  // 특허 프로젝트 수
  useEffect(() => {
    axios.get("/api/projects/list")
      .then((response) => {
        // console.log(response.data.data)
        const projectAPI = response.data.data;

        // todayFormat을 이용하여 7일 전 날짜 계산
        const sevenDaysAgo = dayjs(today).subtract(7, 'day').endOf('day');

        // 필터링: created_time이 7일 전보다 이전인 항목만
        const filteredData = projectAPI.filter((item) =>
          dayjs(item.created_at).isBefore(sevenDaysAgo)  // created_time이 7일 전 이전인지 확인
        );

        // 상태 업데이트
        setLastWeekAppliProject(filteredData);
        setAppliProject(projectAPI);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 1:1 문의 수 데이터 가공
  function changeData(arr, index1){
    arr.forEach((item) => {
      const value1 = item[index1];
      item[index1] = value1.split('T')[0];
    })
    return arr;
  }
  
  // 1:1 문의 수
  useEffect(() => {
    axios.get("/api/memberCenter/weekDirectList")
      .then((response) => {
        // console.log(response.data.data)
        const directAPIData = changeData([...response.data.data],"request_date");
        setDirectList(directAPIData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 승인 대기 정비 수
  useEffect(() => {
    axios.get("/api/applicant/assigneeHistory?search_type=TOTAL")
      .then((response) => {
        const applicantAPIData = response.data.data
        const waitApplicantData = applicantAPIData.filter(item => item.change_flag == 0)
        setWaitAppliData(waitApplicantData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  return(
      <D.TotalTopDiv>
        <D.BoxNameH6>서비스 운영 현황</D.BoxNameH6>
        <D.TopDiv>
          {/*전체 회원 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(1)}}>
              <Widget title="전체 회원 수">
                <D.CategoryNumDiv>
                  <D.CategoryName>
                    {memberList? memberList : 0}
                  </D.CategoryName>
                  <D.CategoryCnt
                    style={{
                      color:
                        filteredMember > 0
                          ? "#3085D6"
                          : filteredMember < 0
                            ? "#FF6B7E"
                            : "#6E6E6E",
                      marginLeft: "5px",
                      fontSize: "16px",
                    }}
                  >
                    &nbsp;
                    {filteredMember
                      ? filteredMember > 0
                        ? "지난 주 +" + filteredMember
                        : "지난 주 -" + filteredMember
                      : 0}
                  </D.CategoryCnt>
                </D.CategoryNumDiv>
              </Widget>
          </D.TopInnerDiv>
          {/*플랜 회원 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(2)}}>
              <Widget title="플랜 회원 수">
                {topPlanMemCnt?.length === 3 && topPlanMemCnt[1] ? (
                  <D.CategoryNumDiv>
                    <D.CategoryName>{topPlanMemCnt[1] ?? 0}</D.CategoryName>
                    <D.CategoryCnt
                      style={{
                        color:
                          topPlanMemCnt?.length === 3
                            ? topPlanMemCnt[2] > 0
                              ? "#3085D6"
                              : topPlanMemCnt[2] < 0
                                ? "#FF6B7E"
                                : "#6E6E6E"
                            : "#6E6E6E",
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: "16px",
                      }}
                    >
                      &nbsp;
                      {topPlanMemCnt?.length === 3
                        ? topPlanMemCnt[2] === 0
                          ? "0"
                          : (topPlanMemCnt[2] > 0 ? "지난 주 +" : "지난 주 -") + topPlanMemCnt[2]
                        : "0"}
                    </D.CategoryCnt>
                  </D.CategoryNumDiv>
                ) : (
                  <D.CategoryNumDiv>
                    <D.CategoryName>0</D.CategoryName>
                    <D.CategoryCnt
                      style={{
                        color: "#FF6B7E",
                        fontWeight: "bold",
                      }}
                    >
                      0
                    </D.CategoryCnt>
                  </D.CategoryNumDiv>
                )}
              </Widget>
          </D.TopInnerDiv>
          {/*통합 프로젝트 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(3)}}>
              <Widget title="통합 프로젝트 수">
                <D.CategoryNumDiv>
                  <D.CategoryName>
                    {totalProject?.length.toLocaleString() || 0}
                  </D.CategoryName>
                  <D.CategoryCnt
                    style={{
                      color:
                        totalProject && lastWeekTotalProject
                          ? totalProject.length - lastWeekTotalProject.length > 0
                            ? "#3085D6"
                            : totalProject.length - lastWeekTotalProject.length < 0
                              ? "#FF6B7E"
                              : "#6E6E6E"
                          : "#6E6E6E", // 기본값 (데이터 부족 시)
                      fontWeight: "bold",
                      marginLeft: "5px",
                      fontSize: "16px",
                    }}
                  >
                    &nbsp;
                    {totalProject && lastWeekTotalProject
                      ? totalProject.length - lastWeekTotalProject.length === 0
                        ? "0" // 0일 때 단순 표시
                        : (totalProject.length - lastWeekTotalProject.length > 0
                        ? "지난 주 +"
                        : "지난 주 -") + (totalProject.length - lastWeekTotalProject.length)
                      : "0"}
                  </D.CategoryCnt>
                </D.CategoryNumDiv>
              </Widget>
          </D.TopInnerDiv>
          {/*특허 프로젝트 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(4)}}>
              <Widget title="특허 프로젝트 수">
                <D.CategoryNumDiv>
                  <D.CategoryName>
                    {appliProject?.length.toLocaleString() || 0}
                  </D.CategoryName>
                  <D.CategoryCnt
                    style={{
                      color:
                        appliProject && lastWeekAppliProject
                          ? appliProject.length - lastWeekAppliProject.length > 0
                            ? "#3085D6"
                            : appliProject.length - lastWeekAppliProject.length < 0
                              ? "#FF6B7E"
                              : "#6E6E6E"
                          : "#6E6E6E", // 기본값 (데이터 없을 때)
                      fontWeight: "bold",
                      marginLeft: "5px",
                      fontSize: "16px",
                    }}
                  >
                    &nbsp;
                    {appliProject && lastWeekAppliProject
                      ? appliProject.length - lastWeekAppliProject.length === 0
                        ? "0" // 변화 없을 때 "0"만 표시
                        : (appliProject.length - lastWeekAppliProject.length > 0
                        ? "지난 주 +"
                        : "지난 주 -") + (appliProject.length - lastWeekAppliProject.length)
                      : "0"}
                  </D.CategoryCnt>
                </D.CategoryNumDiv>
              </Widget>
          </D.TopInnerDiv>
          {/*1:1 문의 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(5)}}>
              <Widget title="1:1 문의 수">
                <D.CategoryName>
                  {directList?.length.toLocaleString() || 0}
                </D.CategoryName>
              </Widget>
          </D.TopInnerDiv>
          {/*승인 대기 정비 수*/}
          <D.TopInnerDiv onClick={() => {navigateToPage(6)}}>
              <Widget title="승인 대기 정비 수">
                <D.CategoryName>
                  {waitAppliData?.length.toLocaleString() || 0}
                </D.CategoryName>
              </Widget>
          </D.TopInnerDiv>
        </D.TopDiv>
      </D.TotalTopDiv>
  );
}

export default ServiceStatusTable;