import styled from 'styled-components';
import { useState } from "react";

import { FaRegEdit } from "react-icons/fa";
import {
  TableCell
} from "@material-ui/core";

const P = {
  HandleDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      
      margin-bottom: 24px;
  `,

  HeaderInnerDiv: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
  `,

  FilterSelect: styled.select`
      width: 250px;
      height: 52px;
      
      margin-top: 20px;
  `,

  FilterDiv: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
  `,

  EndP: styled.div`
    font-weight: bold;
    color: #EA6A6A;  
  `,

  EditableDiv: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
  `,

  FaRegEditIcon: styled(FaRegEdit)`
      position: relative;
      top: -2px;
      margin-left: 10px;
      
      font-size: 20px;
      cursor: pointer;
      &:hover {
          color: #3085D6;
      }
  `,

  TableCellEdit: styled(TableCell)`
      text-align: center;
  `,

  TableCellHead: styled(TableCell)`
      text-align: center;
      cursor: default;
  `,

  TableCellHeadDiv: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  `,

  sortBtn: styled.img`
      width: 18px;
      height: 18px;
      opacity: 0.5;

      &:hover {
          opacity: 1;
      }
  `,

  /*    createPlan    */

  TotalDiv: styled.div`
      width: 80%;
      
      position: relative;
      left: 10%;
      
      font-size: 0.8vw;
    
      border-radius: 0.5vw;
      
      display: flex;
      justify-content: center;
      align-items: center;
  `,

  InnerTotalDiv: styled.div`
      background-color: white;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 1vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 50%;
      margin: 3vw 0vw;
      padding: 4vw 0vw;
  `,

  TitleH4: styled.div`
      font-size: 1.5vw;
      font-weight: bold;
      
      margin-bottom: 50px;
  `,

  BasicDiv: styled.div` // 세로 정렬
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      
      width: 20vw;
      margin-bottom: 2vw;
  `,


  BasicLabel: styled.div`
      margin-bottom: 0.2vw;
      font-weight: bolder;
  `,

  Input: styled.input`
      width: 20vw;
      height: 2vw;

      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.7vw;

      &:focus {
          border-color: #536DFE;
          outline: none;
      }
  `,

  CheckResultP: styled.p`
      color: #FF6868;
      font-size: 0.8vw;
      font-weight: bold;
      
      white-space: pre-line;
      
      margin: 0vw 0vw;
  `,

  ClassSelect: styled.select`
      width: 20vw;
      height: 2vw;
      
      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.7vw;

      &:focus {
          border-color: #536DFE;
          outline: none;
      }
  `,

  SubmitBtn: styled.button`
      width: 10vw;
      height: 2.8vw;

      background-color: #536DFE;
      font-size: 0.8vw;
      color: white;

      border: none; /* 테두리 제거 */
      border-radius: 0.5vw; /* 모서리 둥글게 */
      cursor: pointer; /* 버튼에 마우스 올릴 때 포인터 커서 */

      margin-top: 2vw;
      
      &:hover {
          background-color: #4154d0; /* 호버 시 색상 변경 */
      }
  `,
}

export default P;