import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import CouponListTable from "./components/CouponListTable";
import { IoFilter } from "react-icons/io5";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";

import styled from "styled-components";
import P from "../../styled-components/planStyle"
import { Btn } from "../../styled-components/basicStyle"

import { Link } from "react-router-dom";

function Plan(){
  //pagination 변수들: 받아오는 리스트, 나열할 리스트, 페이지
  const [searchInput, setSearchInput] = useState('');

  // 세부 행 관리

  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <>
      <PageTitle title="플랜 관리" />
      <P.HandleDiv>
        <SearchPaper
          component="form"
          sx={{ display: "flex", alignItems: "center", width: "400" }}
        >
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          <SearchInput
            placeholder="Search"
            inputProps={{ "aria-label": "Search" }}
            onChange={getValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
        </SearchPaper>
        <P.HeaderInnerDiv>
          <Link to="/app/researchAll/plan/createPlan">
            <Btn>플랜 생성</Btn>
          </Link>
        </P.HeaderInnerDiv>
      </P.HandleDiv>
      <Grid item xs={12}>
        <Widget>
          <CouponListTable
            filterKeyword={searchInput}
          />
        </Widget>
      </Grid>
    </>
  );
}

export default Plan;

const SearchInput = styled(InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    width: 20%;
`
