import { atom, selector, useRecoilCallback } from "recoil";

const tableSettingState = atom({
  key: "tableSettingState",
  default: {
    memberTableSetting: "total",
    projectTableSetting: "total",
    noticeTableSetting: "notice",
  },
});

export const memberTableState = selector({
  key: "memberTableState",
  get: ({ get }) => get(tableSettingState).memberTableSetting,
  set: ({ get, set }, memberTableSetting) => set(tableSettingState, { ...get(tableSettingState), memberTableSetting }),
});

export const projectTableState = selector({
  key: "projectTableState",
  get: ({ get }) => get(tableSettingState).projectTableSetting,
  set: ({ get, set }, projectTableSetting) => set(tableSettingState, { ...get(tableSettingState), projectTableSetting }),
});

export const noticeTableState = selector({
  key: "noticeTableState",
  get: ({ get }) => get(tableSettingState).noticeTableSetting,
  set: ({ get, set }, noticeTableSetting) => set(tableSettingState, { ...get(tableSettingState), noticeTableSetting }),
});